<app-navbar></app-navbar>


<ngx-spinner bdColor="#070623" size="medium" color="#f26a50" type="ball-spin-clockwise" [fullScreen]="true">

    <p style="color: white" style="margin-top: 400px;"> LOADING </p>
</ngx-spinner>

<!-- Loader -->
<div id="loader-wrapper">
    <div id="loader"></div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
</div>

<div class="containers" >
    <!-- 1st section banner-->
    <section class="row tm-section ">


        <div class="row-sm-12 row-md-12 row-lg-6 row-xl-6 ">
            <div *ngIf="aboutUs" class="tm-flex-center pb-5" >
                <video  class="video-fluid"  autoplay loop [muted]="'muted'" playsInline>
                        <source [src]="aboutUs[0].video[1]" type="video/mp4" />
                      </video>


                <div class="line-yellow-banner1"></div>
                <div class="line-yellow-banner2"></div>
                <div class="line-yellow-banner3"></div>

            </div>

        </div>


    </section>





    <!-- section2 -->
    <section class="row tm-section ml-30" style="background-color: #070623;">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0">
            <div style="text-align: left;" class="p-5 tm-bg-color-primary tm-section-min-h">
                <p class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy;">about loxiy</p>

                    <div class="dis-growth-section">LOXIY was founded on the notion that human-centered automation will pervade practically every area of life, altering our interactions. Automation evoked ideas of soulless robots and ineffective communication, but this was not our goal. We set out to develop a solution to enable automation that was both practical and beneficial for clients, as well as creating engaging and delightful experiences. The feel and flow of these interactions would be similar to the natural human-to-human discussions we have every day. This automation would also need to be simple to adopt and expand for enterprises. As founders, we've been fortunate in attracting exceptional AI leaders who are eager to cooperate with us, share our vision, and join our team. Their exceptional efforts and insights have resulted in today's world-leading technology.</div>

            </div>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">

            <div class="tm-flex-center section2-img">


                <img src="assets/img/about-us/1.jpg" class="img-fluid section-one" alt="">
                <!--  <div  class="line-yellow-plus-horizontal "></div>
<div class="line-yellow-plus-vertical "></div> -->
            </div>

        </div>
    </section>
    <!-- section3 -->
    <section class="row tm-section ml-30" style="background-color: #070623;">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div style="text-align: left;" class=" pl-5 pr-5 tm-bg-color-primary tm-section-min-h">
                <p class="tm-text-color-white tm-site-name title-text-growth" style="font-family: heavy;">our mission</p>

                <div class=" dis-growth-section">Building and designing exceptional smart machines that improve people's lives. It's a huge task to create machines that can mimic people's movement, dexterity, and agility. Our work on smart machines is driven by curiosity and respect for the natural world.</div>
                <p class="tm-text-color-white tm-site-name title-text-growth" style="font-family: heavy;">our vission</p>

                <div class=" dis-growth-section">Enhance people's lives with artificial intelligence technology.</div>

            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="tm-flex-center p-5 mr-10">
                <img src="assets/img/about-us/2.jpg" class="img-fluid section-two" alt="">


            </div>
        </div>


    </section>
    <!-- 4th Section -->
    <section class="row tm-section ml-30" style="background-color: #070623;">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
            <div style="text-align: left;" class="p-5 tm-bg-color-primary tm-section-min-h">
                <h2 class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy;">our value</h2>

                <div class="dis-growth-section">LOXIY is a values-driven company comprised of a diverse team with a united focus. to push the boundaries of what’s possible with smart technology through courage, passion, innovation, and integrity. more than buzz words, these values form
                    the foundation of our company and help guide us in all our decision-making.</div>

            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="tm-flex-center p-5 mr-10">

                <img src="assets/img/about-us/3.jpg" alt="Image" class="img-fluid section-three">
                <!--  <div class="line-yellow-horizontal1"></div>
           <div class="line-yellow-plus-vertical "></div>
 -->


            </div>
        </div>
    </section>
    <!-- end -->

    <!-- 5th Section -->
    <section class="row tm-section ml-30" style="background-color: #070623;">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 p-0">
            <div style="text-align: left;" class="p-5 tm-bg-color-primary tm-section-min-h">


                <h2 class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy;">OUR CLIENTS</h2>

                <div class="dis-growth-section">Ask employees what makes us different and you’ll hear one thing repeatedly: our people, our values, and our mission. From fast growth to the implementation of new ideas, LOXIY is the place where you let your imagination soar.</div>

            </div>

        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="tm-flex-center p-5 mr-10">

                <img src="assets/img/about-us/4.jpg" alt="Image" class="img-fluid section-four">



            </div>
        </div>

    </section>
    <!-- end -->


    <!-- 6th Section -->
    <section class="row tm-section ml-30" style="background-color: #070623;">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div style="text-align: left;" class="p-5 tm-bg-color-primary tm-section-min-h">
                <h2 class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy;">GROWTH OPPORTUNITY</h2>

                <div class="dis-growth-section">There is an enormous chance to expand in markets especially LOXIY founded in the technology golden era.</div>


                <h2 class="tm-text-color-white tm-site-name title-texts" style="font-family: heavy;">COMPETITION</h2>

                <div class="dis-growth-section">Technology companies are working hard to find a great opportunity to control markets around the world thus we have to race against time.</div>

            </div>

        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="tm-flex-center p-5 mr-10">

                <img src="assets/img/about-us/5.jpg" alt="Image" class="img-fluid section-four">
                <!--  <div class="line-yellow-horizontal1"></div>
           <div class="line-yellow-plus-vertical "></div>
 -->


            </div>

        </div>
    </section>
    <!-- end -->

    <!-- profile section7 -->
    <section class="team-container mr-auto mr-auto mb-5 " style="background-color: #070623;">
        <div class="pt-5  ml-auto mr-auto">
            <p class="idealProcess" style="font-family: heavy; color:#f26a50">LEADERSHIP TEAM</p>
            <p class="idealProcess pb-5" style="font-family: light; ">Creators, developers and innovators.</p>
        </div>
        <div class="row active-with-click" *ngIf="teams">
            <div class="col-md-3 col-sm-6 col-xs-12 ml-auto mr-auto" *ngFor="let team of teams;let i = index">
                <article class="material-card black " [id]="'material-card'+ i">
                    <h2>
                        <span style="font-family: light;">  {{team.userName}}</span>
                        <strong style="font-family:  light;">
                <i   class="fa fa-fw fa-star" style="font-family: 'FontAwesome' "></i>
               {{team.job}}
            </strong>
                    </h2>
                    <div class="mc-content">
                        <div class="img-container ">
                            <img class="img-responsives" [src]="team.img">
                        </div>
                        <div class="mc-description" style="font-family: light;">
                            {{team.description}}
                        </div>
                    </div>
                    <a (click)="fun(i)" class="mc-btn-action" [id]="'mc-btn-action' + i">
                        <i class="fa fa-bars" style="font-family: 'FontAwesome' "></i>
                    </a>
                    <div class="mc-footer">


                    </div>
                </article>

            </div>
        </div>
    </section>
    <!-- end profile -->

    <!-- section8 -->
    <!-- <section class="row tm-section tm-mb-30" style="background-color: #070623;">

        <img class="background-img" src="assets/img/about-us/6.png" alt="">

    </section> -->

    <!-- end -->

    <!-- section8 -->

    <!-- end -->



    <!-- contact1 -->
    <div class="section " style="background-color: #070623;">
        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-7 wide-col-laptop">
                    <div class="contact-section">
                        <div class="row">

                            <div class="col-md-6 ">
                                <div class="title-block ">
                                    <!-- <span>Contact</span> -->
                                    <p class="lates" style="font-family: heavy; color: white; ">let's talk</p>
                                    <p class="text-desc">do you hove any questions for us? call us, tweet us, reach us on out social media platforms or if you aren't bothered. fill out the form below:</p>
                                </div>

                                <div class="last-checkbox-container">
                                    <label class="container" for="inPersonal" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" (change)="setRadio('inPersonal')"  id="inPersonal" name="connect"  value="inPersonal">&nbsp;
                              <span class="checkmark"></span>InPersonal
                            </label>&nbsp;&nbsp;
                                    <label class="container" for="videoCall" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" (change)="setRadio('videoCall')" id="videoCall" name="connect" value="videoCall">&nbsp;
                              <span class="checkmark"></span>Video Call
                            </label>&nbsp;&nbsp;

                                    <label class="container" for="phoneCall" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" (change)="setRadio('phoneCall')" id="phoneCall" name="connect" value="phoneCall">&nbsp;
                              <span class="checkmark"></span>Phone Call
                            </label>
                                </div>
                                <form class="form-container" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Name">
                                    </div>
                                    <div class="input-field">
                                        <input type="email" class="form-control" id="email" name="email" #email="ngModel" ngModel email required placeholder="Email">
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="companyName" name="companyName" #companyName="ngModel" ngModel companyName placeholder="Company Name (if any)">
                                    </div>

                                    <div class="input-field ">
                                        <input type="text" class="form-control  " id="websiteUrl" name="websiteUrl" #websiteUrl="ngModel" ngModel websiteUrl placeholder="Website Url " />
                                    </div>


                                    <div class="input-field ">

                                        <input type="text" class="form-control " name="phone" id="phone" ng2TelInput #phone="ngModel" ngModel phone required placeholder="Phone Number" />

                                    </div>






                                    <div class="input-field  ">
                                        <textarea class="form-control " id="message" name="message" #message="ngModel" ngModel message required placeholder="Message"></textarea>
                                    </div>

                                    <button class="btn-submit" type="submit" id="submit" value="Submit" [disabled]="agree==false">Request a quote</button>
                                    <!-- <input  type="checkbox" name="agreement" class="agreement"  [checked]="agree"
                                (change)="agree = !agree"  > I agree to these <a href="/terms" style="color: #fdca33;">Terms and Conditions </a> -->
                                    <div class="last-checkbox-container " style="flex-direction: column; margin-top: 5rem;">

                                        <label class="container" style="text-align: left;  letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family: light; ">
                                    <input type="checkbox" name="agreement" class="agreement"
                                    (change)="agree" >
                                    <span class="checkmark"></span>By submitting this form, you authorize LOXIY to use your personal data to respond to your requests and queries.
                                  </label>
                                        <label class="container" style="text-align: left;  letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family:light; ">
                                    <input type="checkbox" name="agreement" class="agreement"
                                    (change)="agree"  >
                                    <span class="checkmark"></span>By ticking this box, you agree that we may contact you about LOXIY news, offers, and promotional materials. For more information visit the LOXIY privacy policy.
                                  </label>

                                    </div>


                                </form>
                            </div>
                            <div class="col-12 col-md-6 d-flex align-items-center">
                                <!-- <div class="col-12 col-md-7 d-flex align-items-center"> -->
                                <!-- <div class="tm-flex-center p-5" > -->
                                <!--                                 <img *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu'" class="img-responsive" src="assets/img/team-page2.png" alt="Image" >
                                <img *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' || homeId=='t1f8bVAOY9dC4Z15JM6V'"  class="img-responsive" src="assets/img/graphic-des/Asset-41.png" alt="Image" >
                                <img *ngIf="homeId=='jCStic4eEL39erKVEfmV' || homeId=='jId5W3MrWlwE1q9NtpHt'" class="img-responsive" src="assets/img/Ai Software/3.png" alt="Image" > -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <img class="img-responsive"src="assets/img/team-page4.png"  alt="Image">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="" style="background-color: #070623;">
        <app-footer></app-footer>


    </div>
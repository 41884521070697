import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})



export class AboutUsComponent implements OnInit {
   teams
  letter=[]
  aboutUs
   radio="inPersonal"
   agree=true
    constructor(private route:ActivatedRoute,private fs:FireService) {

     }



    ngOnInit(): void {
    this.getAllAboutSlides()


     this.getAllTeam()

      $(function() {
        $('.material-card  .mc-btn-action').click(function () {
            var card = $(this).parent('.material-card');
            var icon = $(this).children('i');
            icon.addClass('fa-spin-fast');

            if (card.hasClass('mc-active')) {
                card.removeClass('mc-active');

                window.setTimeout(function() {
                    icon
                        .removeClass('fa-arrow-left')
                        .removeClass('fa-spin-fast')
                        .addClass('fa-bars');

                }, 800);
            } else {
                card.addClass('mc-active');

                window.setTimeout(function() {
                    icon
                        .removeClass('fa-bars')
                        .removeClass('fa-spin-fast')
                        .addClass('fa-arrow-left');

                }, 800);
            }
        });
    });

  }

     fun(i){

      var card = $('.material-card  #mc-btn-action' + i).closest('.material-card');
      var icon = $('.material-card  #mc-btn-action' + i).children('i');
      icon.addClass('fa-spin-fast');

      if (card.hasClass('mc-active')) {
          card.removeClass('mc-active');

          window.setTimeout(function() {
              icon
                  .removeClass('fa-arrow-left')
                  .removeClass('fa-spin-fast')
                  .addClass('fa-bars');

          }, 800);
      } else {
          card.addClass('mc-active');

          window.setTimeout(function() {
              icon
                  .removeClass('fa-bars')
                  .removeClass('fa-spin-fast')
                  .addClass('fa-arrow-left');

          }, 800);
      };

    }


   getAllAboutSlides(){
    this.fs.getAboutSliders().subscribe(data => {
      this.aboutUs = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })


    });
  }
    create(value){
      value.createdAt= new Date();

      value.contactType=this.radio
      value.agrement=this.agree
      this.fs.createContactRequests(value).then(()=>{

      });
    }
     myFunction() {
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("more");
      // var btnText = document.getElementById("myBtn");


        dots.style.display = "none";
        // btnText.innerHTML = "Read less";
        moreText.style.display = "inline";

    }
    setRadio(val){
      this.radio=val
    }
/*   ngOnInit(): void {


  } */

  getAllTeam(){
    this.fs.getTeam().subscribe(data => {
      this.teams = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
    });
  };



}

import { Component, ViewChild, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {filter}from 'rxjs/operators'


declare var gtag;
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'loxiy';
  constructor(router:Router){
    const navEndEvents=router.events.pipe(
      filter(event=>event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event:NavigationEnd)=>{
      gtag('config', 'G-S166CBY4TJ',{
        'page_path':event.urlAfterRedirects
      });

    })
  }



}

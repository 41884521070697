import { BrowserModule, } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HomeComponent } from '../app/home/home.component';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './shared/footer/footer.component';
import { FooterTeamComponent } from './shared/footer-team/footer-team.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MdbModule } from 'mdb-angular-ui-kit';
import { NgxLoadingModule } from 'ngx-loading';
import { homeProfileComponent } from './homeProfile/homeProfile.component';
import { AboutUsComponent} from  "./about-us/about-us.component";
import { CareerComponent } from './career/career.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PartnersComponent } from './partners/partners.component';
import { ProductsComponent } from './products/products.component';
import { BlogSingleComponent } from './single-blog/single-blog.component';
import { productProfileComponent } from './productProfile/productProfile.component';
import { BlogComponent } from './blog/blog.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxSpinnerModule } from "ngx-spinner";

import {  termsComponent} from './terms/terms.component';
import { privacyComponent } from './privacy/privacy.component';

@NgModule({
  declarations: [termsComponent,privacyComponent,FooterComponent,LoaderComponent,FooterTeamComponent,NavbarComponent,AppComponent,HomeComponent,homeProfileComponent, AboutUsComponent, CareerComponent,ContactUsComponent,PartnersComponent,ProductsComponent,BlogSingleComponent,productProfileComponent,BlogComponent ],
  imports: [MdbModule,NgxSpinnerModule,  NgxLoadingModule.forRoot({}),
    BrowserModule, CommonModule,NgImageSliderModule,
    AppRoutingModule, AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, FormsModule, BrowserAnimationsModule,
  ],

  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { FireService } from '../services/fire.service';
import { CommonModule } from '@angular/common';
declare var $:any;
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  products
  homeSliders
  constructor(private fs:FireService) { }

  
  ngOnInit(): void {
    

  this.getProducts();
    this.getAllHomeSlides();
  }
  getAllHomeSlides(){
    this.fs.getHomeSliders().subscribe(data => {
      this.homeSliders = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
  

    });
  }
  getProducts(){
    this.fs.getProducts().subscribe(data => {
      this.products = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
  

    });
  }

}

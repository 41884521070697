<!-- Footer -->
<footer class="text-white mt-5">
    <!-- Grid container -->
    <div class="container p-4">
        <!-- Section: Form -->
        <section class="mb-5">
            <form action="">
                <!--Grid row-->
                <div class="row d-flex justify-content-center">
                    <!--Grid column-->
                    <div class="col-auto">
                        <p class="pt-2 ">
                            <strong class="title">Subscribe for our newsletter</strong>
                        </p>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-md-5 col-12">
                        <!-- Email input -->
                        <form class="makeresponsive" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                            <div>
                                <input type="email" name="email" style="color: white; text-align: start;" #email="ngModel" ngModel email required placeholder="Your Email" />
                                <button style="font-family:heavy; text-decoration: none;" type="submit" class="submiting">Subscribe</button>
                            </div>
                        </form>
                    </div>
                    <!--Grid column-->
                </div>
                <!--Grid row-->
            </form>
        </section>
        <!-- Section: Form -->

        <!-- Section: Links -->
        <section class="text-left mt-5" style="border-bottom: 1px solid #fff; padding: 0 0 24px">
            <!--Grid row-->
            <div class="row mt-5">
                <!--Grid column-->
                <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase" style="color: #f26a50;">Info</h5>

                    <ul class="list-unstyled mb-0">
                        <li>
                            <a class="text-white">ISTANBUL - TURKEY:</a>
                        </li>
                        <li>
                            <a class="text-white">Sultan Selim Mahallesi Eski Büyükdere</a>
                        </li>
                        <li>
                            <a class="text-white">Caddesi No:61 Blok A Kat:2 Kağıthane</a>
                        </li>


                        <li>
                            <a class="text-white">00905372811802</a>
                        </li>
                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase" style="color: #f26a50;">Emails</h5>

                    <ul class="list-unstyled mb-0">
                        <li>
                            <a class="text-white">info@loxiy.com</a>
                        </li>
                        <li>
                            <a class="text-white">support@loxiy.com</a>
                        </li>
                        <li>
                            <a class="text-white">developers@loxiy.com</a>
                        </li>

                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase" style="color: #f26a50;">Other branches</h5>

                    <ul class="list-unstyled mb-0">
                        <li>
                            <a class="text-white">Ankara - Turkey</a>
                        </li>
                        <li>
                            <a class="text-white"> Dubai - UAE</a>
                        </li>
                        <li>
                            <a class="text-white"> Chicago - USA</a>
                        </li>


                    </ul>
                </div>
                <!-- <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <img style="cursor: pointer;" (click)="home()" width="175px" src="assets/img/loxiyassets/LOGO/1.svg" />
                   
                </div> -->
                <!-- <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        </div> -->
                <!--Grid column-->
            </div>
            <!--Grid row-->
        </section>
        <!-- Section: Links -->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div class="text-center ">
        <!-- Section: Social media -->
        <section class="mb-4  ">
            <!-- Facebook -->
            <a class="btn btn-outline-light btn-floating pt-1 " href="http://www.facebook.com/loxiy1/" role="button" target="_blank"><i  class="fab fa-facebook fa-2x" style="font-family: 'FontAwesome' "></i
      ></a>

            <!-- Twitter -->
            <a class="btn btn-outline-light btn-floating ml-2 pt-1" href="https://www.twitter.com/Loxiy2" role="button" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-twitter fa-2x"></i
      ></a>



            <!-- Instagram -->
            <a class="btn btn-outline-light btn-floating ml-2 pt-1" href="http://www.instagram.com/loxiy_1/" role="button" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-instagram fa-2x"></i
      ></a>

            <!-- Linkedin -->
            <a class="btn btn-outline-light btn-floating ml-2 pt-1" href="https://www.linkedin.com/company/loxiy/" role="button" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-linkedin-in fa-2x"></i
      ></a>
            <a class="btn btn-outline-light btn-floating ml-2 pt-1" href="https://www.youtube.com/channel/UCXnNCRmfMKvb2-BU7Nc_S3w" role="button" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-youtube fa-2x"></i
        ></a>



        </section>
        <!-- Section: Social media -->
        © 2021
        <a class="text-color"  (click)="home()">LOXIY</a>
        <section>
            <a href="/privacy" target="_blank" style="color: #f26a50;"> Privacy Policy </a>
            <span style="  display: inline-block;
      border-left: 1px solid #ccc;
      margin: 0 10px;
      height: 15px
      ;"></span>
            <a href="/terms" target="_blank" style="color: #f26a50;">Terms and Conditions </a>
        </section>
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->
<app-navbar>
</app-navbar>





    <!--  <header   class="header-bg  scroll">
        <div class="container-fluid">


        </div>
    </header> -->

    <ngx-spinner bdColor="#070623" size="medium" color="#f26a50" type="ball-spin-clockwise" [fullScreen]="true">

        <p style="color: white" style="margin-top: 400px;"> LOADING </p>
    </ngx-spinner>


    <div id="fullpage" class="fullpage-default">
        <div class="section animated-row banner-background " data-section="slide01">
            <div class="section-inner ">
                <div class="text-container"></div>

                <div class="description-text"> </div>
                <div class="welcome-box">
                    <span style="margin-top: 100px;" class="welcome-first animate" data-animate="fadeInUp"></span>
                    <h1 style="text-transform: uppercase;font-family: heavy;color: #070623;" data-animate="fadeInUp">hi, discover loxiy now</h1>
                    <span>
                    <div style="margin-top: 180px;font-size: 20px;color: #070623;" class="welcome-first animate" data-animate="fadeInUp" class="arrow-down">
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" display="block" id="ChevronDown"><path d="M4 9l8 8 8-8"/></svg>                </div>
                 
                    
                    </span>
                    <!-- <img src="assets/images/mouse-scroll.png" alt=""> -->
                </div>

                <div>


                </div>


            </div>
        </div>




        <!-- <div class="section animated-row" data-section="slide02" style="background-color: #070623;">
            <div class="section-inner">
                <div class="about-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wide-col-laptop">
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="about-contentbox">
                                        <div class="animate" data-animate="fadeInUp">
                                            <h1 style="font-family: heavy">build your website now</h1>
                                            <p class="text--normal">As we all are aware of the evolution of the new digital world, thus companies around the world have to move their own businesses to online website. We are working exhaustively towards weaving the dreams of many
                                                to find their space over the Internet.</p>
                                            <h6>GET AN EXPERIENCED DEDICATED DEVELOPER FROM US.
                                            </h6>
                                            <h5 *ngIf="homeSliders" class="text--More" >
                                                <a target="_blank" class="btn only" [routerLink]="['../homeProfile',homeSliders[0].id]" skipLocationChange >Request now
                                                <i class='fas fa-arrow-right fa-color fa-lg' style="font-family: 'FontAwesome' "></i></a>
                                            </h5>
                                        </div>
                                        <div data-animate="fadeInUp" class="animate checkbox-container">
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled >
                                            <span class="checkmark" ></span><p >24-7 support</p>
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span> <p >free domain</p>
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span><p >hosting</p>
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="homeSliders">

                                    <video id="v1" preload="none" loop [muted]="'muted'" playsInline>
                                    <source [src]="homeSliders[0].img" type="video/mp4">
                                </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="section animated-row" data-section="slide03" style="background-color: #070623;">
            <div class="section-inner">
                <div class="about-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wide-col-laptop">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="about-contentbox">
                                        <div class="animate" data-animate="fadeInUp">
                                            <h1 style="font-family: heavy">build your application
                                            </h1>
                                            <p class="text--normal">You know how’s important and significant that you own an application to present your business, offer many products and services to customers, and creating an attachment with them to be in touch all your time
                                                with your customers.
                                            </p>
                                            <h6>
                                                GET AN EXPERIENCED DEDICATED DEVELOPER FROM US. </h6>
                                            <h5 *ngIf="homeSliders" class="text--More">
                                                <a target="_blank" class="btn only" [routerLink]="['../homeProfile',homeSliders[1].id]">Request now
                                                <i class='fas fa-arrow-right fa-color fa-lg' style="font-family: 'FontAwesome' "></i></a>
                                            </h5>
                                        </div>
                                        <div data-animate="fadeInUp" class="animate checkbox-container">
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>android & ios
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>24-7 support
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>Native App
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="homeSliders" class="col-md-6">
                                    <video id="v2" preload="none" loop [muted]="'muted'" playsInline>
                                    <source [src]="homeSliders[1].img" type="video/mp4">
                                </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="section animated-row" data-section="slide04" style="background-color: #070623;">
            <div class="section-inner">
                <div class="about-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wide-col-laptop">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="about-contentbox">
                                        <div class="animate" data-animate="fadeInUp">
                                            <h1 style="font-family: heavy">engage your customers </h1>
                                            <p class="text--normal">We are your graphic designers, developers, and marketing guides, ready to help take your business to the next level. What we design is a direct reflection of your brand: we aim for each experience to be purposely
                                                built and engaging.</p>
                                            <h6>ALL GRAPHIC DESIGN DIGITAL AND PRINTING NEEDS FOR COMPANIES.
                                            </h6>
                                            <h5 *ngIf="homeSliders" class="text--More">
                                                <a target="_blank" class="btn only" [routerLink]="['../homeProfile',homeSliders[2].id]">order now
                                                <i class='fas fa-arrow-right fa-color fa-lg' style="font-family: 'FontAwesome' "></i></a>
                                            </h5>
                                        </div>
                                        <div data-animate="fadeInUp" class="animate checkbox-container">
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>Creativity
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>Innovation
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>High quality
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="homeSliders" class="col-md-6">
                                    <video id="v3" style="height: 450px;  margin-top: 50px; margin-bottom: 50px" preload="none" loop [muted]="'muted'" playsInline>
                                    <source [src]="homeSliders[2].img" type="video/mp4">
                                </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="section animated-row" data-section="slide05" style="background-color: #070623;">
            <div class="section-inner">
                <div class="about-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wide-col-laptop">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="about-contentbox">
                                        <div class="animate" data-animate="fadeInUp">
                                            <h1 style="font-family: heavy">boost your audiece
                                            </h1>
                                            <p class="text--normal">Videos that speak the language of your brand, Let LOXIY come together to create videos that meticulously convey your story to those who matter.</p>
                                            <h6>
                                                GET YOUR ATTRACTIVE VIDEO NOW.
                                            </h6>
                                            <h5 *ngIf="homeSliders" class="text--More">
                                                <a target="_blank" class="btn only" [routerLink]="['../homeProfile',homeSliders[3].id]">order now
                                                <i class='fas fa-arrow-right fa-color fa-lg' style="font-family: 'FontAwesome' "></i></a>
                                            </h5>
                                        </div>
                                        <div data-animate="fadeInUp" class="animate checkbox-container">
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>Creativity
                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>Bright colours

                                        </label>
                                            <label class="container">
                                            <input type="checkbox" checked="checked" value="disabled" disabled>
                                            <span class="checkmark"></span>Modern
                                        </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="homeSliders" class="col-md-6">
                                    <video id="v4" preload="none" loop [muted]="'muted'" playsInline>
                                    <source [src]="homeSliders[3].img" type="video/mp4">
                                </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="section animated-row" data-section="slide06" style="background-color: #070623;">
            <div class="section-inner">
                <div class="about-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wide-col-laptop">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="about-contentbox">
                                        <div class="animate" data-animate="fadeInUp">
                                            <h1 style="font-family: heavy">
                                                Smart Products</h1>
                                            <p class="text--normal">Recent innovations in artificial intelligence and sensor technologies allow for creating a digital representation of almost any physical entity and its parameters over time at any place. These technologies make life just a little bit easier.
                                                <h6>
                                                    Loxiy technology enhances people's lives by innovating artificial intelligence products.
                                                </h6>
                                                <h5 *ngIf="homeSliders" class="text--More">
                                                    <a target="_blank" class="btn only" [routerLink]="['../products']">view Products
                                                <i class='fas fa-arrow-right fa-color fa-lg' style="font-family: 'FontAwesome' "></i></a>
                                                </h5>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="homeSliders">
                                    <video id="v5" style="height: 450px;  margin-top: 50px; margin-bottom: 50px" preload="none" loop [muted]="'muted'" playsInline>
                                    <source [src]="homeSliders[4].img" type="video/mp4">
                                </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="section animated-row" data-section="slide07" style="background-color: #070623;">
            <div class="section-inner">
                <div class="about-section">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wide-col-laptop">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="about-contentbox">
                                        <div class="animate" data-animate="fadeInUp">
                                            <h1 class="header-text" style="font-family: heavy">Ai SYSTEMS</h1>
                                            <p class="text--normal">We build outstanding systems LOXIY provides Custom System design and development services to clients from all around the world, transforming big ideas into exceptional products.</p>
                                            <h6>
                                                LEARN MORE ABOUT LOXIY SYSTEMS.

                                            </h6>
                                            <h5 *ngIf="homeSliders" class="text--More">
                                                <a target="_blank" class="btn only" [routerLink]="['../homeProfile',homeSliders[5].id]">Request now
                                                <i class='fas fa-arrow-right fa-color fa-lg' style="font-family: 'FontAwesome' "></i></a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="homeSliders">
                                    <video id="v6" style="height: 450px;  margin-top: 50px; margin-bottom: 50px" preload="none" loop [muted]="'muted'" playsInline>
                                    <source [src]="homeSliders[5].img" type="video/mp4">
                                </video>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->




        <div class="section animated-row" data-section="slide12" style="background-color: #070623;">
            <div class="section-inner">
                <div class="row justify-content-center">
                    <div class="col-md-7 wide-col-laptop">
                        <div class="contact-section">
                            <div class="row">

                                <div class="col-md-6 ">
                                    <div class="title-block">
                                        <!-- <span>Contact</span> -->
                                        <p class="lates" style="font-family: heavy; color: white; ">
                                            let's talk</p>
                                        <p class="text-desc">Do you have questions for us? call us, reach us on our social media platforms, or if you aren't bothered. Kindly fill out the form below:
                                        </p>
                                    </div>

                                    <div class="last-checkbox-container">
                                        <label class="container" for="inPersonal" style="font-size: 10px; letter-spacing: 0.10em;">
                                        <input type="radio" checked (change)="setRadio('inPersonal')" id="inPersonal"
                                            name="connect" value="inPersonal">&nbsp;
                                        <span class="checkmark"></span>InPersonal
                                    </label>&nbsp;&nbsp;
                                        <label class="container" for="videoCall" style="font-size: 10px; letter-spacing: 0.10em;">
                                        <input type="radio" (change)="setRadio('videoCall')" id="videoCall"
                                            name="connect" value="videoCall">&nbsp;
                                        <span class="checkmark"></span>Video Call
                                    </label>&nbsp;&nbsp;

                                        <label class="container" for="phoneCall" style="font-size: 10px; letter-spacing: 0.10em;">
                                        <input type="radio" (change)="setRadio('phoneCall')" id="phoneCall"
                                            name="connect" value="phoneCall">&nbsp;
                                        <span class="checkmark"></span>Phone Call
                                    </label>
                                    </div>
                                    <form class="form-container" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                                        <div class="input-field">
                                            <input type="text" class="form-control" id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Name">
                                        </div>
                                        <div class="input-field">
                                            <input type="email" class="form-control" id="email" name="email" #email="ngModel" ngModel email required placeholder="Email">
                                        </div>
                                        <div class="input-field">
                                            <input type="text" class="form-control" id="companyName" name="companyName" #companyName="ngModel" ngModel companyName placeholder="Company Name (if any)">
                                        </div>

                                        <div class="input-field ">
                                            <input type="text" class="form-control  " id="websiteUrl" name="websiteUrl" #websiteUrl="ngModel" ngModel websiteUrl placeholder="Website" />
                                        </div>


                                        <div class="input-field ">

                                            <input type="text" class="form-control " name="phone" id="phone" ng2TelInput #phone="ngModel" ngModel phone required placeholder="Phone Number" />

                                        </div>






                                        <div class="input-field  ">
                                            <textarea class="form-control " id="message" name="message" #message="ngModel" ngModel message required placeholder="Message"></textarea>
                                        </div>
                                        <div style="align-items: flex-end;text-align: left;">

                                            <input type="checkbox" name="agreement" class="agreement" [checked]="agree" (change)="agree = !agree" style="margin-bottom: 12px;"> I agree with the <a href="/terms" style="color: #f26a50;"> LOXIY  Terms and Conditions </a>
                                        </div>
                                        <button class="btn-submit" type="submit" id="submit" value="Submit" [disabled]="agree==false">Request a quote</button>


                                        
                                    </form>
                                </div>
                                <div class="col-12 col-md-6 d-flex align-items-center">
                                    <!-- <div class="col-12 col-md-7 d-flex align-items-center"> -->
                                    <!-- <div class="tm-flex-center p-5" > -->
                                    <img class="img-responsive" src="assets/img/team-page3.png" alt="Image">
                                    <!-- </div> -->
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="line-height: 2;" class="section animated-row" data-section="slide13" style="background-color: #070623;">

            <app-footer></app-footer>
        </div>
    </div>

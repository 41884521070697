<!--start section Latest Blog Post-->
<app-navbar></app-navbar>

<ngx-spinner bdColor="#070623" size="medium" color="#f26a50" type="ball-spin-clockwise" [fullScreen]="true">

    <p style="color: white" style="margin-top: 400px;"> LOADING </p>
</ngx-spinner>
<header>
    <div class="overlay">
    </div>

    <h1 style="font-family: heavy;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    font-weight: 700;
    text-transform: uppercase;"> Blog <br></h1>
</header>

<!-- <div class="Latest-Post" *ngIf="blogs">
    <div class="container">

       <div class="row">
         <div class="col-xs-12 col-lg-4">
            <div class="card hvr-wobble-vertical">
              <img class="card-img-top" [src]="blogs[0].img" alt="Card image cap">
              <div class="card-body">

               <h4 class="card-title">{{blogs[0].title}} </h4>
               <span class="card-subtitle"> {{blogs[0].date}}</span>
              <p class="card-text p">{{blogs[0].description}}</p>
              </div>
        </div>
          <div class="card-body only">
          <a [routerLink]="['/blogSingle', blogs[0].id]" class="card-link text-uppercase">Read More</a>
         </div>
      </div>



       </div>
    </div>
  </div> -->

<div class="Latest-Post" *ngIf="blogs">
    <div class="container">

        <div class="row">
            <!--start elemnt 1-->
            <div class="col-xs-12 col-lg-4" *ngFor="let item of blogs">
                <div class="card hvr-wobble-vertical">
                    <img class="card-img-top" height="200px" [src]="item.img" alt="Card image cap">
                    <div class="card-body">

                        <h4 class="card-title" style="font-family: heavy;">{{item.title}} </h4>
                        <!-- <span class="card-subtitle"> {{item.date}}</span> -->
                        <div class="card-text p" style="font-family: light;" [innerHtml]="item.description"></div>
                        <!-- <p class="card-text p">{{item.description}}</p> -->
                    </div>
                </div>
                <div class="card-body only">
                    <a [routerLink]="['/blogSingle', item.id]" class="card-link text-uppercase" style="font-family: light;">Read More</a>
                </div>
            </div>
            <!--start elemnt 1-->



        </div>
    </div>

</div>
<app-footer></app-footer>

<!--end section Latest Blog Post-->
import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
declare var $:any;
@Component({
  selector: 'blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
blogs
  constructor(private route:ActivatedRoute,private fs:FireService) { }

  ngOnInit(): void {
    this.getAllBlog();

  }
  getAllBlog(){
    this.fs.getBlog().subscribe(data => {
      this.blogs = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
  

    });
  }
}

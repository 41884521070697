<!-- Footer -->
<footer class="text-white mt-5">
    <!-- Grid container -->
    <div class="container p-4">
        <!-- Section: Form -->
        <section class="mb-5">
            <form action="">
                <!--Grid row-->
                <div class="row d-flex justify-content-center">
                    <!--Grid column-->
                    <div class="col-auto">
                        <p class="pt-2">
                            <strong class="title">Subscribe for our newsletter</strong>
                        </p>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-md-5 col-12">
                        <!-- Email input -->
                        <form #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                            <div>
                                <input type="email" name="email" style="color: white;" #email="ngModel" ngModel email required placeholder="Your Email" />
                                <button style="font-family: heavy;" type="submit" class="btn only">Subscribe</button>
                            </div>
                        </form>
                    </div>
                    <!--Grid column-->
                </div>
                <!--Grid row-->
            </form>
        </section>
        <!-- Section: Form -->

        <!-- Section: Links -->
        <section class="text-left mt-5" style="border-bottom: 1px solid #fff; padding: 0 0 24px">
            <!--Grid row-->
            <div class="row mt-5">
                <!--Grid column-->
                <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Info</h5>

                    <ul class="list-unstyled mb-0">
                        <li>
                            <a class="text-white">Umraniye - 36473 – Istanbul, Turkey.</a>
                        </li>
                        <li>
                            <a class="text-white"> Kizilay - 22941 – Ankara, Turkey.</a>

                        </li>
                        <li>
                            <a class="text-white">00905338230978</a>
                        </li>
                        <li>
                            <a class="text-white">00905372811802</a>
                        </li>
                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-5 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Emails</h5>

                    <ul class="list-unstyled mb-0">
                        <li>
                            <a class="text-white">info@loxiy.com</a>
                        </li>
                        <li>
                            <a class="text-white">team@loxiy.com</a>
                        </li>
                        <li>
                            <a class="text-white">developers@loxiy.com</a>
                        </li>

                    </ul>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <img style="cursor: pointer;" (click)="home()" width="220px" src="assets/img/loxiyassets/LOGO/White-LOGO.png" />

                </div>
                <!-- <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        </div> -->
                <!--Grid column-->
            </div>
            <!--Grid row-->
        </section>
        <!-- Section: Links -->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div class="text-center p-3">
        <!-- Section: Social media -->
        <section class="mb-4">
            <!-- Facebook -->
            <a class="btn btn-outline-light btn-floating m-1" href="http://www.facebook.com/loxiy1/" role="button" target="_blank"><i class="fab fa-facebook-f"></i
      ></a>

            <!-- Twitter -->
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.twitter.com/Loxiy2" role="button" target="_blank"><i class="fab fa-twitter"></i
      ></a>

            <!-- tumblr -->
            <a class="btn btn-outline-light btn-floating m-1" href="https://loxiyy.tumblr.com/" role="button" target="_blank"><i class="fab fa-tumblr"></i
      ></a>

            <!-- Instagram -->
            <a class="btn btn-outline-light btn-floating m-1" href="http://www.instagram.com/loxiy_1/" role="button" target="_blank"><i class="fab fa-instagram"></i
      ></a>

            <!-- Linkedin -->
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/company/loxiy/" role="button" target="_blank"><i class="fab fa-linkedin-in"></i
      ></a>

            <!-- Github -->
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.reddit.com/user/Loxiy" role="button" target="_blank"><i class="fab fa-reddit-alien"></i
      ></a>
        </section>
        <!-- Section: Social media -->
        © 2021 Copyright:
        <a class="text-color" (click)="home()">LOXIY</a>
        <section>
            <a href="/privacy" target="_blank" style="color: #f26a50;"> Privacy Policy</a>
            <span style="  display: inline-block;
      border-left: 1px solid #ccc;
      margin: 0 10px;
      height: 15px
      ;"></span>
            <a href="/terms" target="_blank" style="color: #f26a50;">Terms and Conditions </a>
        </section>
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->
<app-navbar></app-navbar>

<body><header>
  <div class="overlay">
  </div>
  <h1>Terms & Conditions<br></h1>
</header>

  <!-- <main style="background-color:#666666"> -->
    <!-- <article>
     
  
    </article> -->
 

    <div style="text-align: left;">
      <p style="color: white;">The following terms and conditions govern your use of LOXIY website:</p>
      <p style="color: white;">•	The content of LOXIY website's pages is provided solely for your general knowledge and use. It is subject to change at any time without prior notice.
      </p>
      <p style="color: white;">•	Cookies are used on LOXIY website to keep track of your surfing habits. If you allow cookies to be used, we can store the following personal information for third-party use: Name, surname, company, website, phone number, address, and email address.
      </p>
      <p style="color: white;">•	LOXIY make no representations or warranties about the accuracy, timeliness, performance, completeness, or suitability of the information and materials contained or provided on this website for any specific purpose, and neither we nor any third parties make any such representations or warranties. You agree that such information and materials which contain inaccuracies or errors, and to the fullest extent allowed by law, we expressly disclaim responsibility for any such inaccuracies or errors.
      </p>
      <p style="color: white;">•	Any information or materials you obtain from this website are used solely at your own risk, for which we will not be held responsible. You are solely responsible for ensuring that any goods, facilities, or information obtained via this website meet your unique needs.
      </p>
      <p style="color: white;">•	LOXIY website includes content that we own or have permission to use. The design, layout, look, appearance, and graphics are all examples of this content. Other than in compliance with the copyright notice, which is a part of these terms and conditions, reproduction is forbidden.
      </p>
      <p style="color: white;">
        •	LOXIY website acknowledges all trade-marks that are not owned by or licensed to the operator.

      </p>
      <p style="color: white;">
        •	Unauthorized use of LOXIY website may result in a lawsuit and/or be considered a criminal offense.

      </p>
      <p style="color: white;">
        •	LOXIY website may contain links to other websites from time to time. These links have been given for your convenience in order to provide you with additional details. They may not imply that we approve of the website (s). The related website's content is not under our control.

      </p>
      <p style="color: white;">
        •	The laws of the Republic of Turkey govern your use of LOXIY website, as well as any disputes that may arise from it.

      </p>
      <p style="color: white;">Please check our Privacy Policy page for more information regarding your Privacy.
      </p>
      <p style="color: white;">
        TERMS AND CONDITIONS EMAIL: terms@loxiy.com	

      </p>
      <p style="color: white;">
        LOXIY TERMS AND CONDITIONS.

      </p>

      </div>
    


  <!-- </main> -->
</body>



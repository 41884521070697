import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactUs
  radio="inPersonal"
  agree=true


  constructor(private route:ActivatedRoute,private fs:FireService) { }

  ngOnInit(): void {
    this.getcontactUs();
  }

  getcontactUs(){
    this.fs.getContactUs().subscribe(data => {
      this.contactUs = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
  

    });
  }
  create(value){
    value.createdAt= new Date();

    value.contactType=this.radio
    value.agrement=this.agree
    this.fs.createContactRequests(value).then(()=>{
  
    });
    
  }
  setRadio(val){
    this.radio=val
  }
  Whatsapp(text){
    window.open('https://api.whatsapp.com/send?phone=+905372811802&text='+text);  
  }
}

<app-navbar></app-navbar>

<body><header>
  <div class="overlay">
  </div>
  <h1>Privacy Policy<br></h1>
</header>

  <!-- <main style="background-color:#666666"> -->
    <!-- <article> -->
     
   <div style="text-align: left;">
    <p><strong>LOXIY</strong> appreciates your privacy and confidentiality. We are committed to protecting and only using such information for the purposes set out in this Privacy Policy with adequate protection. This <strong>Privacy Policy</strong> helps you understand what personally identifiable information is collected and processed, how it is protected, how we use it, and which rights your personal data have.</p>
    <p>This Privacy Policy (the 'Policy') explains how the company that is incorporated in the laws of the Republic of Turkey, <strong>(collected, with its affiliates and subsidiaries, 'LOXIY,' 'we, we,' 'our,')</strong> collects and processes, in conformity with General Data Protection Regulation <strong>(2016/679)</strong> and the applications, your personal data, i.e., information gathered on the web and offline.</p>
    <p><strong>Definitions of terms used in Policy:</strong></p>
    <p><strong>'Data Controller'</strong> refers to the person or organization that determines when and why and how to process personal data and implement suitable technical and organizational compliance measures;</p>
    <p><strong>'Data subjected'</strong> means an individual whose personal information we keep is alive, known, or identifiable;</p>
    <p><strong>'Data Protection Officer'</strong> means the person who has been formally appointed to ensure that our data protection responsibilities and obligations under legislation are known and complied with;</p>
    <p><strong>'Personal data'</strong> means data about the Data Subject who can be identified:</p>
    <ul>
    <li>from that data;</li>
    <li>from that data and other information to which we have or are likely to have access;</li>
    </ul>
    <p><strong>'Processing'</strong> means any operation or operation which is carried out on Personal Data or personal data collecting, registering, organizing, structuring, storing, adapting or altering, recollection, consultation, usage, transmission, disclosure, dissemination or otherwise accessible, alignment or combination, constraints, erasures or d dissemination of personal data;</p>
    <p><strong>'Personnel Details Special categories'</strong> means records disclosing racial or ethnic heritage, political views, religious or related convictions, membership in the Union, conditions of physical and mental fitness, sexual life, sexual identity, biometric or genetic data;</p>
    <p>Personal data comprises special categories of personal information for the purposes of this Policy.</p>
    <p><strong>'Third Party'</strong> means your Personal Data receiver as described below.</p>
    <p>&nbsp;</p>
    <p><strong>The kind of information we collect about you:</strong></p>
    <p>The scope of processing your personal data is primarily based on each of the services we ask while using:</p>
    <ul>
    <li>Information provided when you submit to 'BOOK A STRATEGY SESSION' or contact us via our website for example. Name, email address. Email address.</li>
    <li>You collect information from our website or from Google Analytics, for example, by cookies. Page views, IP address and mobile device Id.</li>
    <li>Information collected from contracts and arrangements between us for the supply and implementation of our services, for example. Name, Address, ID, Biography, VAT number.</li>
    </ul>
    <p>Without the express approval of their parents or legal guardian, we do not store or process children's personal data.</p>
    <p><strong>We process your Personal Data on what legal basis:</strong></p>
    <p>As data controllers, for any or more of the following reasons, we may capture and process your personal data:</p>
    <ul>
    <li><strong>Legal obligations:</strong>e., the duty to register accounts in accordance with Cypriot Tax Authorities, for activities which require us to treat personal data;</li>
    <li><strong>Consent:</strong> The collection of your personal details was granted to you for a particular reason, for example requesting on our website, response to your inquiries, email notices and requested commercial records.</li>
    </ul>
    <p>The consent can be revoked at any time by contacting the contact information given below or by personally advising our Data Protection Officer;</p>
    <ul>
    <li><strong>Legitimate Interest:</strong> Personal Information can, except where such interest is overridden by your needs, or constitutional rights and liberties, particularly where the data subject is an infant, may be required in order for our legitimate interests or for third parties' interests to conduct, facilitate, evaluate and support our services.</li>
    </ul>
    <p>Send business newsletters and we consider interesting notices, send general commercial messages not considered advertising; Treat your or your questions and inquiries, Enhance your experience with your browser;</p>
    <ul>
    <li><strong>Contractual obligations:</strong> We need some personal information from you to offer this service, such as the construction of websites and the development of social media, for the start and the continuity of services with you, or as a customer or service provider. service rate sent; service quota sent;</li>
    </ul>
    <p><strong>Who gets the personally identifiable information:</strong></p>
    <p>In order to provide our services to the following third parties, your personal data is sometimes shared or made available for us to the best possible standards:</p>
    <ul>
    <li>Employees, associates or partners that require access to meet the above-mentioned purposes.</li>
    <li>Providers of utilities, including but not limited to providers of IT services supporting our services.</li>
    <li>Payment Processing Providers.</li>
    </ul>
    <p>In the event of your lack of agreement, your personal data, unless required to reveal it in accordance with some law, policy or on a request from a governmental supervisory, competent authority will not be revealed to any of the other third parties except the abovementioned.</p>
    <p>A privacy and non-disclosure agreement have been negotiated for our staff.</p>
    <p><strong>Conservation of personal data: </strong></p>
    <p>After seven years your relationship with you has been terminated, we will cease maintaining your personal information or remove the means by which the personal data is associated with you; and/or, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal information was collected and is no longer required by law.</p>
    <p><strong>Personal data security: </strong></p>
    <p>We have taken the requisite administrative and physical and technological steps, including current anti-virus protection, encryption and the use of data filters to safeguard any storing or transmitting personal data to Third Parties, in order to protect your personal information against non-authorized access, processing, use, harm, damage, loss, disclosure, copying, or similar risk. We also only authorize those workers who need to know such data and only process the personal data according to our guidelines to access personal data.</p>
    <p>However, there is no entirely safe method of transmitting through internet or electronic storage. Although protection cannot be guaranteed, we aim to safeguard the safety of the personal data of the data subject and continuously revise and improve the security of our records.</p>
    <p><strong>Your rights in relation to your Personal Data:</strong></p>
    <p><strong>Access right:</strong></p>
    <p>Ask you to view your Personal Data, so you can get a copy of our Personal Data.</p>
    <p><strong>Portability of the right to data: </strong></p>
    <p>Request that the data be transferred to someone else.</p>
    <p><strong>Right to correct: </strong></p>
    <p>Request that all of the Personal Data held by us be corrected or updated.</p>
    <p><strong>Entitlement to delete:</strong></p>
    <p>Request that your personal data be deleted. However, certain material, such as record recording and detection of illegal activity, will need to be retained for legal or administrative reasons.</p>
    <p><strong>Right to object: </strong></p>
    <p>The storage and use of your Personal Data are subject to your right.</p>
    <p><strong>Right to limit processing: </strong></p>
    <p>Request to prevent personal data from being used.</p>
    <p><strong>Right to lodge a claim:</strong></p>
    <p>By calling the Office of the Information Protection Commissioner in Turkey you have the right to lodge a lawsuit about the use of your personal data.</p>
    <p><strong>You should contact our Data Protection Officer in writing or via mail if you want to exercise any of your rights in relation to the following contact information:</strong></p>
    <p><strong>Name: Data Protection Officer.</strong></p>
    <p><a href="mailto:DPO@loxiy.com"><strong>DPO@loxiy.com</strong></a></p>
    <p>The Data Protection Officer has the right to require the individual making the request to provide certain identification documents/information to be able to verify his/her identity.</p>
    <p>The Data Protection Officer will respond to your requests within ten (10) days after receiving your email/letter.</p>
    <p><strong>Policy impact and policy changes:</strong></p>
    <p>We shall continue to monitor this policy and can change it from time to time without warning. To ensure you are aware of all other modification/updates, you can check our policy regularly on our website.</p>
    <p><strong>COOKIES POLICY: </strong></p>
    <p>Cookie technology is used on our website. Cookies are lightweight, tracking, saving, storing information as well as your connections and uses on our website, saved to your machine or mobile device. The main aim of gathering user data on our platform is to allow us to deliver an effective and customized experience through use of our site. Users should take appropriate measures, within the protection configurations of their web browsers, to block all cookies from this platform and its external service providers, whether they want to refuse usage or save them from this website on their computer's hard disk.</p>
    <p>We will also gather other types of non-personal information, such as internet browsers, search keywords, traffic references, and links to our website. We may also collect information on this website. We use cookies obtained by us to allow some website features and software, to assist in web browsing, to monitor resources and data on this site, and to remember device settings. By changing the functionality on your web browser, you can prevent your machine from accepting cookies (see the "Help" button on your browser).</p>
    <p>We also analyze traffic to our website with the services of Google Analytics tools. Neither of these systems generates a visitor record or we gather some information on personal identity when using these services. Data gathered on the use of the website is taken together to enhance the website's results. If you do not want to use your details in these aggregated data from Google Analytics, change the properties of your browser to avoid cookies from being accepted by your computer or mobile device. Learn the privacy policies for Google.</p>
    <p><strong>Cookie Types:</strong></p>
    <p><strong>Navigating Cookies:</strong> These cookies allow the site to operate properly and collect information about the use of the site by users. This knowledge is used to collect documentation and to enhance the website. Cookies collect anonymous information, including the number of users visiting and the sites visited, where visitors come from.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>Strictly needed cookies: </strong></p>
    <p>These cookies are required for the website to allow you to access certain functions of the website such as special services, configuration and form logging. The blocking or alerting you about these cookies is possible on your computer, but then other areas of the web won't run. No publicly identifying information is stored in these cookies.</p>
    <p><strong>Analytical Cookies:</strong></p>
    <p>These cookies are used to provide comparative analysis of user navigation of a website, the number of pages visited or the number of clicks made during site navigation. Analytical cookies may be used to provide a numerical analysis.</p>
    <p><strong>Functionality Cookies: </strong></p>
    <p>These are used to help the platform to keep track of choices you make (such as language) and to boost your web experience.</p>
    <p><strong>Deactivation of Cookies: </strong></p>
    <p>By configuring settings on your browser, you can avoid the setting of cookies (see the "Help" feature of your browser). Please note that the deactivation of cookies will affect the functions of this and other sites you visit.</p>
    <p><strong>Privacy Policy Email: </strong><a href="mailto:polices@loxiy.com	"><strong>polices@loxiy.com</strong></a></p>
    <p><strong>LOXIY Privacy Policy.</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>All Rights Reserved for LOXIY</strong></p>
  </div>
  
    <!-- </article> -->
 
 
  <!-- </main> -->
</body>



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CommonModule, } from '@angular/common';
import { homeProfileComponent } from './homeProfile/homeProfile.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CareerComponent } from './career/career.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PartnersComponent } from './partners/partners.component';
import { ProductsComponent } from './products/products.component';
import { BlogSingleComponent } from './single-blog/single-blog.component';
import { productProfileComponent } from './productProfile/productProfile.component';
import { BlogComponent } from './blog/blog.component';
import {  termsComponent} from './terms/terms.component';
import { privacyComponent } from './privacy/privacy.component';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'home',     component: HomeComponent
// ,
// canActivate: [CountGuardService]
},
  { path: 'homeProfile/:id',     component: homeProfileComponent

  // ,
  // canActivate: [CountGuardService]
 },
 { path: 'aboutus',     component: AboutUsComponent
 // ,
 // canActivate: [CountGuardService]
},
{ path: 'career',     component: CareerComponent
 // ,
 // canActivate: [CountGuardService]
},
{ path: 'contactus',     component: ContactUsComponent
 // ,
 // canActivate: [CountGuardService]
},
{ path: 'partners',     component: PartnersComponent
 // ,
 // canActivate: [CountGuardService]
},
{ path: 'products',     component: ProductsComponent
 // ,
 // canActivate: [CountGuardService]
},
{ path: 'blogSingle/:id',     component: BlogSingleComponent
 // ,
 // canActivate: [CountGuardService]
},
  { path: 'productProfile/:id',     component: productProfileComponent
 // ,
 // canActivate: [CountGuardService]
},
  { path: 'blog',     component: BlogComponent
 // ,
 // canActivate: [CountGuardService]
},
{ path: 'privacy',     component: privacyComponent
  // ,
  // canActivate: [CountGuardService]
 },{ path: 'terms',     component: termsComponent
 // ,
 // canActivate: [CountGuardService]
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }

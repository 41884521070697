import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
declare var $:any;
import {finalize } from 'rxjs/operators';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
   teams
    subSelectForJo=[];
    cv
    letter=[]
      public loading = false;
     radio="inPersonal"
   agree=true
  constructor(private route:ActivatedRoute,private fs:FireService) { }

  ngOnInit(): void {
    this.getAllTeam();

  }





  getAllTeam(){
    this.fs.getTeam().subscribe(data => {
      this.teams = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
    });
  }
  cvUpload(){
    document.getElementById('cv').click();
  }
  letterUpload(){
    document.getElementById('letter').click();
  }
  create(value){
    this.loading = true;
     value.createdAt= new Date();

      value.contactType=this.radio
      value.agrement=this.agree
      this.fs.createContactRequests(value).then(()=>{

      });
    value.letter=this.letter
    var cv = this.fs.uploadCvFromHireMe(this.cv, 'cvFromHireME');

    if (cv) {
      cv.snapshotChanges().pipe(
        finalize(() => {
          cv.then(upload => {
            upload.ref.getDownloadURL().then((downloadurl) => {
              value.cv = downloadurl;
              value.createdAt= new Date();

              this.fs.createHireMeRequests(value).then(()=>{
                this.loading = false;

              });
          });
          });
        })).subscribe();
      }

  }
  selectCv(event){
    if(event.target.files.length > 0) {
      const file = event.target.files[0];
      this.cv = file;
    }
  }

  selectCoverLetter(event){
    this.loading = true;

    for (let i = 0; i < event.target.files.length; i++) {
      var task = this.fs.uploadFiles(event, i, 'letterFromHireME')
      task.snapshotChanges().pipe(
        finalize(() => {
          task.then(uploadSnapshot => {
            uploadSnapshot.ref.getDownloadURL().then((downloadURLProfile) => {
              this.letter.push(downloadURLProfile);
              this.loading = false;

            })
          })
        })
      )
        .subscribe()
        this.loading = false;

    }

  }
  onChange(jobValue) {
    if(jobValue==1){
      this.subSelectForJo=['Web Designer','Front End Developer','Back End Developer','Full Stack Developer',
    'UI-UX Designer','Domain Engineer','Interaction Designer','Art Director','Web Developer','Content Strategist',
    'IT Technician','Cyber security','Dev Ops','Product Manager','Customer Service Representative','SEO Specialist',
  'Other ....']

    }
    else if(jobValue==2){
      this.subSelectForJo=['Application Developer','Android Developer','IOS Developer','UI-UX application Design','Other ....']
    }
    else if(jobValue==3){
      this.subSelectForJo=['Junior Graphic designer','Senior Graphic designer','Production artist','UX designer',
      'Multimedia designer','Animator','Art director','Layout artist','Book designer','Social media designs',
      'Printing designer','Other ....']
    }else if(jobValue==4){
      this.subSelectForJo=['Television Studio Editor','Film Editor','Senior Video Editor','Multimedia Artist','Animator'
      ,'Documentary video','Motion graphic editor','Other ....']

    }
    else if(jobValue==5){
      this.subSelectForJo=['SEO Manager','SEO Engineer','Digital Marketing Manager','Digital Marketing Analyst','Social Media Marketing Manager'
      ,'Social Media Marketing Analyst','Marketing Technologist','SEO Consultant','Web Analytics Developer'
      ,'Digital Marketing Manager','Social media specialist','Growth Hacker','Content Manager',
      'Content Strategist']

    }
    else if(jobValue==6){
      this.subSelectForJo=['Data Engineer','Decision-Maker','Analyst','Expert Analyst','learning Machine','Statistician',
    'Applied Machine Learning Engineer','Data Scientist','Analytics Manager','Qualitative Expert','Researcher','Domain expert'
  ,'Ethicist','Software engineer','Reliability engineer','Interactive visualizer','Data collection specialist','Data product manager'
,'Project/program manager','Other ....']

    }
    else if(jobValue==7){
      this.subSelectForJo=['Chief Architect','Software Architect','Engineering Project Manager',
      'Technical Leader','Principal Software Engineer','Senior Software Developer','Software Engineer'
      ,'Software Developer','Junior Software Developer','Intern Software Developer','Other ....']


    }
}
 setRadio(val){
      this.radio=val
    }
}

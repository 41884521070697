<app-navbar></app-navbar>

<body>
    <header>
        <div class="overlay">
        </div>
        <h1 style="font-family: heavy;"> Blog <br> </h1>
    </header>

    <!-- <main >
    <article> -->
    <div style=" background-color: 070623;
      /* max-width: 1000px; */
      padding: 3rem 0 4rem;
      margin: 2rem auto;
      box-shadow: 0 4px 20px -4px 070623" *ngIf="blog">
        <div style=" margin: auto;
      padding: 3.8rem;">


            <div>
                <h1 class="post__title">{{blog.title}}</h1>
            </div>
            <div *ngIf="blog" class="post__featured-image post__image-wide">
                <img [src]="blog.img" />
            </div>
            <div class="post__entry">
                <p style="text-align: left;" [innerHtml]="blog.description"></p>

            </div>
            <div class="svg-group">
                <ul class="list-inline list-unstyled">
                    <li class="list-inline-item"><a href="http://www.facebook.com/loxiy1/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li class="list-inline-item"><a href="https://www.twitter.com/Loxiy2" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li class="list-inline-item"><a href="https://loxiyy.tumblr.com/" target="_blank"><i class="fab fa-tumblr"></i></a></li>
                    <li class="list-inline-item"><a href="http://www.instagram.com/loxiy_1/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li class="list-inline-item"><a href="https://www.linkedin.com/company/loxiy/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <!--<li class="list-inline-item"><a href="https://www.tiktok.com/@loxiy_1"><i class="fab fa-tiktok"></i></a></li>-->
                    <li class="list-inline-item"><a href="https://www.reddit.com/user/Loxiy" target="_blank"><i class="fab fa-reddit-alien"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- </article>
  </main> -->
</body>
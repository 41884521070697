import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  contactUs
  constructor(private route:ActivatedRoute,private fs:FireService) { }

  ngOnInit(): void {
  }
  home() { window.location.href = "/home"; }
  getcontactUs(){
    this.fs.getContactUs().subscribe(data => {
      this.contactUs = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })


    });
  }

  create(value){
    value.createdAt= new Date();
    this.fs.createEmailMeRequests(value).then(()=>{

    });

  }
  
}

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class termsComponent implements OnInit {

  constructor() {
  
   }

  ngOnInit(): void {
   
  }
  

}

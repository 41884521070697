import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FireService {

  constructor(private firestore: AngularFirestore,private afStorage:AngularFireStorage) { }
  getHomeSliders(){
    return this.firestore.collection('homeSliders',ref => ref.orderBy('rank',"asc")).get();
}
getSpecificHomeSlider(id){
  return this.firestore.collection('homeSliders').doc(id).valueChanges();
}

getAboutSliders(){
  return this.firestore.collection('aboutUs').get();
}

getContactUs(){
  return this.firestore.collection('contactUs').get();
}

getPartners(){
  return this.firestore.collection('partners').get();
}

getTeam(){
  return this.firestore.collection('teams').get();
}
getBlog(){
  return this.firestore.collection('blogs').get();
}
getCounDownTime(){
  return this.firestore.collection('countDownPage').doc('date').valueChanges();
}

getBlogDetail(id){
  return this.firestore.collection('blogs').doc(id).valueChanges();
}
getProducts(){
  return this.firestore.collection('products',ref => ref.orderBy('hashId',"asc")).get();
}
getProductPRofile(id){
  return this.firestore.collection('products').doc(id).valueChanges();

}

getCarousel(id){
  return this.firestore.collection('carousel').doc(id).valueChanges();
}
createContactRequests(page){
  return this.firestore.collection('contactRequests').add(page)
}
createHireMeRequests(page){
  return this.firestore.collection('hireMe').add(page)
}
uploadCvFromHireMe(event, type: string) {
  if (event) {
    const randomId = Math.random().toString(36).substring(2);
    const filePath = `${type}/${randomId}`;
    return this.afStorage.upload(filePath, event);

  }
}
createEmailMeRequests(page){
  return this.firestore.collection('emailContacts').add(page)
}
uploadFiles(event,i,type:string){
  if(event.target.files.length > 0) {
    const randomId = Math.random().toString(36).substring(2);
    const filePath =`${type}/${randomId}`;
    let file = event.target.files[i];
    return this.afStorage.upload(filePath,file);
  }
}
}

<app-navbar></app-navbar>



<ngx-spinner bdColor="#070623" size="medium" color="#f26a50" type="ball-spin-clockwise" [fullScreen]="true">

    <p style="color: white" style="margin-top: 400px;"> LOADING </p>
</ngx-spinner>
<!-- Loader -->
<div id="loader-wrapper">
    <div id="loader"></div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
</div>
<!-- 
<ngx-spinner bdColor="rgba(0, 0, 0, 0)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->
<div class="containers" *ngIf="home">

    <!-- 1st section banner-->
    <section class="row tm-section">
        <div class="pb-5 tm-bg-color-primary tm-section-min-h ml-auto mr-auto">
            <!-- <div class="tm-flex-center "> -->

            <img *ngIf="home.rank==1" src="assets/img/home profile pics/website.png" alt="Image" class=" banner-img">
            <img *ngIf="home.rank==2" src="assets/img/home profile pics/app.png" alt="Image" class=" banner-img2">
            <img *ngIf="home.rank==3" src="assets/img/graphic-des/Asset-8.png" alt="Image" class=" banner-img4">
            <img *ngIf="home.rank==4" src="assets/img/home profile pics/video.png" alt="Image" class=" banner-img3">
            <img *ngIf="home.rank==6" src="assets/img/home profile pics/ai.png" alt="Image" class=" banner-img3">


            <div class="line-yellow"></div>

            <!-- </div> -->
        </div>

        <div class="pb-5 tm-bg-color-primary tm-section-min-h ml-auto mr-auto">
            <p class="text-title" style="font-family: heavy">{{home.title}}</p>

        </div>

    </section>
    <!-- price section2 -->
    <section *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V'" class="" style="background-color: #070623;">
        <h1 class="header-text">choose your website now</h1>
        <p class="header-text-description">Your Business needs something different</p>
        <div class="cards-container pb-5" style="padding-left: 10%;padding-right: 10%;height: auto;">


            <div class="cards">
                <div class="card-pricing first-card">
                    <h3 class="card-title">Website </h3>
                    <h3 class="card-title">package m</h3>


                </div>
                <div class="card-features">
                    <h2 style="color: black;font-weight: bolder;">500 TL</h2>
                    <p style="color: black;">&#8260; month</p>
                    <ul>
                        <li><span class="check-card-one">&#10004;</span>1 Domain</li>
                        <li><span class="check-card-one">&#10004;</span>1 Hosting</li>
                        <li><span class="check-card-one">&#10004;</span>1 SSL Certificate</li>
                        <li><span class="check-card-one">&#10004;</span>25 GBSpace</li>
                        <li><span class="check-card-one">&#10004;</span>1 Database</li>
                        <li><span class="check-card-one">&#10004;</span>500 Email Addresses</li>
                    </ul>
                    <a href="https://wa.me/+905372811802/?text=Website package m" class="card-btn card-one" style="background-color:#0600FF;color:white">choose</a>
                </div>

            </div>
            <!-- 2nd card -->
            <div class="cards pb-3" style="height: 600px;">


                <div class="card-pricing second-card ">
                    <h3 class="card-title">best choice </h3>
                    <h3 class="card-title">website</h3>
                    <h3 class="card-title">package l</h3>


                    <!-- <h3 class="card-title best-card">best choice
                        <span class="underline-part">
                        website package l
                    </span>
                    </h3> -->



                </div>
                <div class="card-features">
                    <h2 style="color: black;font-weight: bolder;">850 TL</h2>
                    <p style="color: black;">&#8260; month</p>
                    <ul>
                        <li><span class="check-card-two">&#10004;</span>1 Domain</li>
                        <li><span class="check-card-two">&#10004;</span>1 Hosting</li>
                        <li><span class="check-card-two">&#10004;</span>1 SSL Certificate</li>
                        <li><span class="check-card-two">&#10004;</span>50 GBSpace</li>
                        <li><span class="check-card-two">&#10004;</span>1 Database</li>
                        <li><span class="check-card-two">&#10004;</span>1000 Email Addresses</li>
                        <li><span class="check-card-two">&#10004;</span>Data Enty</li>
                        <li><span class="check-card-two">&#10004;</span>24 &#8260; 7 Support</li>
                    </ul>
                    <a href="https://wa.me/+905372811802/?text=Website package l" class="card-btn card-two" style="background-color:#FF0013;color:white">choose</a>
                </div>
            </div>
            <!-- 3rd card -->
            <div class="cards">
                <div class="card-pricing third-card">
                    <h3 class="card-title">website</h3>
                    <h3 class="card-title">package xl</h3>

                </div>
                <div class="card-features">
                    <h2 style="color: black;font-weight: bolder;">1150 TL</h2>
                    <p style="color: black;">&#8260; month</p>

                    <ul>
                        <li><span class="check-card-three">&#10004;</span>1 Domain</li>
                        <li><span class="check-card-three">&#10004;</span>1 Hosting</li>
                        <li><span class="check-card-three">&#10004;</span>1 SSL Certificate</li>
                        <li><span class="check-card-three">&#10004;</span>150 GB Space</li>
                        <li><span class="check-card-three">&#10004;</span>1 Database</li>
                        <li><span class="check-card-three">&#10004;</span>2000 Email Addresses</li>
                        <li><span class="check-card-three">&#10004;</span>Data Enty</li>
                        <li><span class="check-card-three">&#10004;</span>24 &#8260; 7 Support</li>

                    </ul>
                    <a href="https://wa.me/+905372811802/?text=Website package xl" class="card-btn card-three" style="background-color:#754C29;color:white">choose</a>
                </div>
            </div>
            <div class="cards">
                <div class="card-pricing forth-card">
                    <h3 class="card-title">website</h3>
                    <h3 class="card-title"> package xxl</h3>


                </div>
                <div class="card-features">
                    <h2 style="color: black;font-weight: bolder;">1350 TL</h2>
                    <p style="color: black;">&#8260; month</p>
                    <ul>
                        <li><span class="check-card-four">&#10004;</span>1 Domain</li>
                        <li><span class="check-card-four">&#10004;</span>1 Hosting</li>
                        <li><span class="check-card-four">&#10004;</span>1SSL Certificate</li>
                        <li><span class="check-card-four">&#10004;</span>250 GB Space</li>
                        <li><span class="check-card-four">&#10004;</span>1 Database</li>
                        <li><span class="check-card-four">&#10004;</span>2000 Email Addresses</li>
                        <li><span class="check-card-four">&#10004;</span>Data Enty</li>
                        <li><span class="check-card-four">&#10004;</span>24 &#8260; 7 Support</li>

                    </ul>
                    <a href="https://wa.me/+905372811802/?text=Website package xxl" class="card-btn" style="background-color:#009444;color:white">choose</a>
                </div>


            </div>


        </div>

        <div class="underlist-text">
            <p style="padding-left: 10%;" class="underlist-text-left">Minimum following contract length:<br><strong>6 months + 100 TL
                    monthly</strong></p>
            <p style="padding-right: 10%;" class="underlist-text-rht">Minimum following contract length:<br><strong>Equel to the initial contract
                        length</strong></p>

        </div>





    </section>
    <!-- price section end -->

    <!-- other service section3-->
    <section class="tm-section " *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V'">
        <h1 class="header-text">other website services</h1>
        <div class="responsive-grid-cols pt-5 pl-5">
            <div class="col-section">
                <ul>
                    <li><span style="font-size:smaller ;">&#10004;</span>commercial website</li>
                    <li><span style="font-size:smaller ;">&#10004;</span>website management</li>
                    <li><span style="font-size:smaller ;">&#10004;</span>ongoing website maintenance</li>
                    <li><span style="font-size:smaller ;">&#10004;</span>google analytics</li>
                    <li><span style="font-size:smaller ;">&#10004;</span>different payment gateways</li>


                </ul>
            </div>
            <div class="col-section">
                <ul>
                    <li><span style="font-size:smaller ;">&#10004;</span> 50 data entry products</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> copywriting</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> 3 different language</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> whatsapp support messages</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> 1 phone Call weekly</li>
                </ul>
            </div>
            <div class="col-section">
                <ul>
                    <li><span style="font-size:smaller ;">&#10004;</span> logo commercial design</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> no sponsorship ads</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> 2 weekly Posters</li>
                    <li><span style="font-size:smaller ;">&#10004;</span> 1 monthly promotion video</li>

                </ul>
            </div>

        </div>
        <div class="description-underlist">
            <h5> important note:</h5>
            <p>FOR PROFESSIONAL BUSINESS WEBSITES AND SYSTEMS, YOU CAN CONTACT OUR TEAM AT <br>at
                <strong href="mailto:web@loxiy.com">web@loxiy.com</strong> TO GET ANOTHER PRICE FOR A SPECIAL WEBSITE OR SYSTEM NEEDS
            </p>

        </div>
    </section>
    <!-- other service end -->



    <!-- section2 -->
    <section class="row tm-section ml-30 " style="background-color: #070623; ">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 ">
            <div style="text-align: left; " class="p-5 tm-bg-color-primary tm-section-min-h ">
                <p class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy;text-align: left; ">
                    {{home.title}}</p>

                <div class="dis " [innerHtml]="home.description "></div>

            </div>
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">

            <div class="tm-flex-center section2-img ">
                <img *ngIf="home.rank==1 " src="assets/img/image-pages/Asset 3.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==2 " src="assets/img/image-pages/Scene_1.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==3 " src="assets/img/home profile pics/6.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==4 " src="assets/img/Editing Video/1.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==6 " src="assets/img/loxiyassets/Software1.png " alt="Image " class="img-fluid ">


                <!--  <div  class="line-yellow-plus-horizontal "></div>
<div class="line-yellow-plus-vertical "></div> -->
            </div>

        </div>
    </section>
    <!-- section3 -->
    <section class="row tm-section ml-30 ">
        <div *ngIf="home.whyThisService!=null " class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0 ">
            <div style="text-align: left; " class=" p-5 tm-bg-color-primary tm-section-min-h ">
                <p class="tm-text-color-white tm-site-name title-text-service " style="font-family: heavy ">Why this service?!</p>


                <div *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' " class="dis-service-section ">When done right, it’s like starting with a blank canvas and creating a masterpiece from scratch. In a continuously evolving digital world, customers are more likely to be searching for online products than those stocked in a brick-and-mortar
                    shop. Therefore, almost all businesses that care to increase their clientele and sales have made the move to the internet. If you are looking to improve your online presence, you are on the right track with us!</div>
                <div *ngIf="homeId=='jId5W3MrWlwE1q9NtpHt' " class="dis-service-section ">When done right, it’s like starting with a blank canvas and creating a masterpiece from scratch. In a continuously evolving digital world, customers are more likely to be searching for online products than those stocked in a brick-and-mortar
                    shop. Therefore, almost all businesses that care to increase their clientele and sales have made the move to the internet.</div>
                <div *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' " class="dis-service-section ">Renew and innovate with us, our main emphasis is on ensuring customer listening, from logo creation, product design, annual reports and national ads. By defining a vision of a brand, we create creative visual stories that draw and create
                    long-term success.</div>
                <div *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu' " class="dis-service-section ">When done right, it’s like starting with a blank canvas and creating a masterpiece from scratch. In a continuously evolving digital world, customers are more likely to be searching for online products than those stocked in a brick-and-mortar
                    shop. Therefore, almost all businesses that care to increase their clientele and sales have made the move to the internet.</div>
                <div *ngIf="homeId=='jCStic4eEL39erKVEfmV' " class="dis-service-section ">Attention on high quality, low costs, and minimum time expenditure of this technology. LOXIY focuses particularly on result-oriented innovative solutions paying the most. We provide structural monitoring solutions and analysis for clients.</div>

            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
            <div class="tm-flex-center p-5 mr-10 ">
                <video class="video-styling " autoplay loop [muted]=" 'muted' " playsInline>
                    <source [src]="home.img " type="video/mp4 ">
                </video>




            </div>
        </div>


    </section>


    <section *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' || homeId=='jCStic4eEL39erKVEfmV' || homeId=='jId5W3MrWlwE1q9NtpHt' " class=" tm-section tm-mb-30 " style="background-color: #070623; ">
        <div class="pt-5 ml-auto mr-auto ">
            <h2 class="idealProcess " style="font-family: heavy ">idealProcess</h2>
        </div>
        <div class="col-12 idealProcess-container ml-2 ">
            <div class="idealProcess-card ">
                <div class="idealProcess-card-pricing idealProcess-first-card ">
                    plan
                </div>
                <div class="idealProcess-card-features ">

                    <p class="idealProcess-des-text " style="text-align: center;padding-left:15px;padding-right:15px;font-family: light;" [innerHTML]="home.plan "></p>
                </div>
            </div>

            <div class="idealProcess-card ">
                <div *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' || homeId=='jId5W3MrWlwE1q9NtpHt'  " class="idealProcess-card-pricing idealProcess-first-card ">
                    design
                </div>
                <div *ngIf="homeId=='jCStic4eEL39erKVEfmV'  " class="idealProcess-card-pricing idealProcess-first-card ">
                    program
                </div>

                <div class="idealProcess-card-features ">

                    <p class="idealProcess-des-text " style="text-align: center;padding-left:15px;padding-right:15px;font-family: light " [innerHTML]="home.design "></p>
                </div>
            </div>

            <div class="idealProcess-card ">
                <div class="idealProcess-card-pricing idealProcess-first-card ">
                    develop
                </div>
                <div class="idealProcess-card-features ">

                    <p class="idealProcess-des-text " style="text-align: center;padding-left:15px;padding-right:15px;font-family: light " [innerHTML]="home.develop "></p>
                </div>
            </div>

            <div class="idealProcess-card ">
                <div class="idealProcess-card-pricing idealProcess-first-card ">
                    track
                </div>
                <div class="idealProcess-card-features ">

                    <p class="idealProcess-des-text " style="text-align: center;padding-left:15px;padding-right:15px;font-family: light " [innerHTML]="home.track "></p>
                </div>
            </div>

            <div class="idealProcess-card ">
                <div class="idealProcess-card-pricing idealProcess-first-card ">
                    test
                </div>
                <div class="idealProcess-card-features ">

                    <p class="idealProcess-des-text " style="text-align: center;padding-left:15px;padding-right:15px;font-family: light " [innerHTML]="home.test "></p>
                </div>
            </div>

            <div class="idealProcess-card ">
                <div class="idealProcess-card-pricing idealProcess-first-card ">
                    launch
                </div>
                <div class="idealProcess-card-features ">

                    <p class="idealProcess-des-text " style="text-align: center;padding-left:15px;padding-right:15px;font-family: light " [innerHTML]="home.launch "></p>
                </div>
            </div>
        </div>

    </section>


    <!-- video Editing sliders -->
    <div class="video-section " *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu' ">
        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h2 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy ">our video services</h2>
                    </div>
                    <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">motion graphic video</h3>
                    <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                        <div *ngFor="let motion of motionGraphic " class="carousel-cell carousel-div ">


                            <img src="{{motion}} " />

                        </div>
                    </carousel> -->
                    <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="motionGraphic " #nav>

                    </ng-image-slider>
                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">documentary video</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let doc of documentaryVideo " class="carousel-cell carousel-div ">


                                <img src="{{doc}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="documentaryVideo " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">products video</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let product of productsVideo " class="carousel-cell carousel-div ">


                                <img src="{{product}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="productsVideo " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy;color:#f26a50; ">CORPORATIONS VIDEO</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let corporation of corporationVideo " class="carousel-cell carousel-div ">


                                <img src="{{corporation}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="corporationVideo " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>
        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">TRANSITIONS VIDEO</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let transition of transationsVideo " class="carousel-cell carousel-div ">


                                <img src="{{transition}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="transationsVideo " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- end -->



    <!-- Graphic design sliders -->
    <div class="video-section " *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' ">
        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h2 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy ">our graphic design service</h2>
                    </div>
                    <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">logo design</h3>
                    <!-- <carousel loop="true " cellsToShow="4 " autoplayInterval="50000 ">
                        <div *ngFor="let logo of logoDesign " class="carousel-cell ">


                            <img src="{{logo}} " />

                        </div>
                    </carousel> -->
                    <!-- <div class="tm-md-flex-center "> -->
                    <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="logoDesign " #nav>

                    </ng-image-slider>
                    <!-- </div> -->



                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">social media posters</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let social of socialMediaPosters " class="carousel-cell carousel-div ">


                                <img src="{{social}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="socialMediaPosters " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color:#f26a50; ">BROCHURES & CATALOGUES</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let cat of catalogue " class="carousel-cell carousel-div ">


                                <img src="{{cat}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="catalogue " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">plastic & carton bags</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let plastic of plasticCarton " class="carousel-cell carousel-div ">


                                <img src="{{plastic}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="plasticCarton " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>
        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">all printing works</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let allprinting of allPrintingWorks " class="carousel-cell carousel-div ">


                                <img src="{{allprinting}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="allPrintingWorks " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>

        <section class="row tm-section tm-mb-30 ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">social media marketing</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let social of socialMediaMarketing " class="carousel-cell carousel-div ">


                                <img src="{{social}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="socialMediaMarketing " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>
        <section class="row tm-section tm-mb-30 " style="background-color: #070623; ">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                    <div class="tm-md-flex-center ">
                        <h3 class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy; color: #f26a50; ">content strategy</h3>
                        <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                            <div *ngFor="let content of contentStrategy " class="carousel-cell carousel-div ">


                                <img src="{{content}} " />

                            </div>
                        </carousel> -->
                        <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="contentStrategy " #nav>

                        </ng-image-slider>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- end -->





    <!-- section5 -->
    <section class="row tm-section ml-30 ">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0 ">
            <div style="text-align: left; " class="p-5 tm-bg-color-primary tm-section-min-h ">
                <h2 class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy ">
                    What is next?</h2>
                <!--
        <div class="dis "    [innerHtml]="home.WhatIsNext "></div> -->
                <div *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' " class="dis ">Not only must websites be available, but also be easy to use and navigate. With design and user experience in mind, your website will not only have a wonderful and unique design, but it will also bring more conversions and thus increase
                    revenues thanks to its user-friendly characteristics and its ability to promote your services, products, and offerings.
                </div>
                <div *ngIf="homeId=='jId5W3MrWlwE1q9NtpHt' " class="dis ">Not only must applications be available, but also be easy to use and navigate. With design and user experience in mind, your application will not only have a wonderful and unique design, it will also bring more conversions and thus increase
                    revenues thanks to its user-friendly characteristics and its ability to promote your services, products and offerings.</div>
                <div *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' " class="dis ">We design visual presentations that concentrate on industry priorities and goals. We partner with founders, transformers and visionaries to develop the most interesting and purposeful designs in the world.</div>
                <div *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu' " class="dis ">We also defined the way the explicator videos are planned, made, scripted, edited, and supported. We are one of the top branded video manufacturers who first work with the consumer to build each of our films. Here, I clarify our entire
                    process so that you're not only pleased with the video but also happy with</div>
                <div *ngIf="homeId=='jCStic4eEL39erKVEfmV' " class="dis ">Not only must the system be available, but also be easy to use and navigate. With design and user experience in mind, your system will not only have a wonderful and unique design, but it will also bring more conversions and thus increase
                    revenues thanks to its user-friendly characteristics.</div>

            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
            <div class="tm-flex-center p-5 mr-10 ">
                <img *ngIf="home.rank==1 " src="assets/img/image-pages/Asset 4.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==2 " src="assets/img/image-pages/Scene_2.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==3 " src="assets/img/loxiyassets/Graphic1.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==4 " src="assets/img/Editing Video/22.png " alt="Image " class="img-fluid ">
                <img *ngIf="home.rank==6 " src="assets/img/Ai Software/2.png " alt="Image " class="img-fluid ">
                <div class="line-yellow-horizontal1 "></div>
                <div class="line-yellow-plus-vertical "></div>



            </div>
        </div>
    </section>

    <!-- 5th Section -->


    <!-- section6 -->
    <section *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' || homeId=='jId5W3MrWlwE1q9NtpHt' || homeId=='jCStic4eEL39erKVEfmV' " class="row tm-section tm-mb-30 " style="background-color: #070623; ">
        <div *ngIf="home.projectsForAwesomeClients!=null " class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                <div class="tm-md-flex-center ">
                    <h2 class="mb-4 tm-text-color-primary " style="font-family: heavy ">Projects for awesome clients:
                    </h2>
                    <div class="project-section mb-5 dis " style="text-align: center;font-family: light; " [innerHtml]="home.projectsForAwesomeClients ">
                    </div>

                    <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                        <div *ngFor="let img of images " class="carousel-cell carousel-div ">


                            <img src="{{img}} " />

                        </div>
                    </carousel> -->
                    <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="images " #nav>

                    </ng-image-slider>




                </div>
            </div>
        </div>
    </section>
    <!-- section7 -->
    <section class="row tm-section ml-30 " *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' ">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0 ">
            <div style="text-align: left; " class="p-5 tm-bg-color-primary tm-section-min-h ">
                <h2 class="tm-text-color-white tm-site-name title-texts " style="font-family: heavy ">
                    reserve domain & Hosting</h2>

                <div *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' " class="dis ">
                    launch your website with a 1-click install. Hosting at low monthly rates. Get a Free Domain and 24-7 Support from Our Experts. Get Started Today! Get Free Domain For 1 Year Free SSL.
                    <div class="click-btn " style="margin-top: 10px; ">
                        <a href="https://api.whatsapp.com/send?phone=+905372811802 " class="btn only ">order now
                            <i class='fas fa-arrow-right fa-color fa-lg'></i></a>

                    </div>
                    <div class="checkbox-container pt-3 ">

                        <label class="container">
                            <input type="checkbox" checked="checked" value="disabled" disabled>
                            <span class="checkmark"></span>free domain
                        </label>
                        <label class="container">
                            <input type="checkbox" checked="checked" value="disabled" disabled>
                            <span class="checkmark"></span>24-7 support
                        </label>
                        <label class="container">
                            <input type="checkbox" checked="checked" value="disabled" disabled>
                            <span class="checkmark"></span>hosting for one year
                        </label>
                    </div>
                </div>


            </div>


        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
            <div class="tm-flex-center p-5 mr-10 ">
                <img src="assets/img/image-pages/Asset 5.png " alt="Image " class="img-fluid make-respon ">

                <div class="line-yellow-horizontal1 "></div>
                <div class="line-yellow-plus-vertical "></div>
            </div>

        </div>

    </section>



    <section class="row tm-section tm-mb-30 " *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' || homeId=='jId5W3MrWlwE1q9NtpHt' || homeId=='jCStic4eEL39erKVEfmV' " style="background-color: #070623; ">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5 ">
                <div class="tm-md-flex-center ">
                    <h2 *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' " class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy ">other website services</h2>
                    <h2 *ngIf="homeId=='jId5W3MrWlwE1q9NtpHt' " class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy ">other apps services</h2>
                    <h2 *ngIf="homeId=='jCStic4eEL39erKVEfmV' " class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy ">OTHER Ai SYSTEM SKILLS</h2>
                    <p class="mb-4 tm-text-color-primary project-text-design " style="font-family: heavy "> However, it doesn't stop there. In order for online dominance, you should also consider the following:
                    </p>
                    <!-- descriptions -->
                    <!-- <carousel arrows="false " autoplay="false " autoplayInterval="50000 ">
                        <div *ngFor="let othServices of otherServices " class="carousel-cell carousel-div ">


                            <img src="{{othServices}} " />

                        </div>
                    </carousel> -->
                    <ng-image-slider [imagePopup]="false " style="margin-right: 20%; " [animationSpeed]="1 " [infinite]="true " [autoSlide]="{interval: 5, stopOnHover: false} " [imageSize]="{space: 50} " [infinite]="true " [showArrow]="false
                    " slideImage="1 " [images]="otherServices " #nav>

                    </ng-image-slider>



                </div>
            </div>
        </div>
    </section>

    <!-- contact -->
    <section class="row ">

        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div class="line-yellow3 "></div>
            <div class="tm-flex-center p-5 ">
                <div class="tm-md-flex-center pb-5 ">

                    <h2 class="mb-4 tm-text-color-primary pt-5 " *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' " style="font-family: heavy ">contact us now </h2>
                    <h2 class="mb-4 tm-text-color-primary pt-5 " *ngIf="homeId=='jId5W3MrWlwE1q9NtpHt' " style="font-family: heavy ">let's talk</h2>
                    <div class="row justify-content-center ">
                        <h2 class="mb-4 tm-text-color-primary pt-5 " *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' || homeId=='BRN7B9CIjT5f8sl5HIBu' || homeId=='jCStic4eEL39erKVEfmV' " style="font-family: heavy ">let's talk </h2>
                        <!-- <h2 class="mb-4 tm-text-color-primary pt-5 pl-5 " *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' || homeId=='BRN7B9CIjT5f8sl5HIBu' " style="font-family: rej text-transform: uppercase; ">order now</h2> -->
                    </div>
                    <div class="row justify-content-center ">
                        <p style="color: #f26a50;font-size: 20px; " *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' ">web@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px; " *ngIf="homeId=='jId5W3MrWlwE1q9NtpHt' ">app@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px; " *ngIf="homeId=='jCStic4eEL39erKVEfmV' ">
                            ai-software@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px; text-align: center " *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' ">graphic-design@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px; text-align: center; " *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu' ">video@loxiy.com</p>

                    </div>

                    <div class="click-btn justify-content-center " style="align-items: center;text-align:center ">
                        <a href="mailto:web@loxiy.com " *ngIf="homeId=='t1f8bVAOY9dC4Z15JM6V' " target="_blank " class="btn only ">click here<i
                                class='fas fa-arrow-right fa-color fa-lg'></i></a>
                        <a href="mailto:app@loxiy.com " *ngIf="homeId=='jId5W3MrWlwE1q9NtpHt' " target="_blank " class="btn only ">click here<i
                                    class='fas fa-arrow-right fa-color fa-lg'></i></a>
                        <a href="mailto:ai-software@loxiy.com " *ngIf="homeId=='jCStic4eEL39erKVEfmV' " target="_blank " class="btn only ">click here<i
                                        class='fas fa-arrow-right fa-color fa-lg'></i></a>
                        <a href="mailto:graphic-design@loxiy.com " *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' " target="_blank " class="btn only ">click here<i
                                            class='fas fa-arrow-right fa-color fa-lg'></i></a>
                        <a href="mailto:video@loxiy.com " *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu' " target="_blank " class="btn only ">click here<i
                                                class='fas fa-arrow-right fa-color fa-lg'></i></a>
                    </div>
                    <!-- href="https://api.whatsapp.com/send?phone=+905372811802 " -->




                </div>
                <div class="line-yellow2"></div>
            </div>
        </div>
    </section>
    <!-- contact1 -->
    <div class="section ">
        <div class="section-inner ">
            <div class="row justify-content-center ">
                <div class="col-md-7 wide-col-laptop ">
                    <div class="contact-section ">
                        <div class="row ">
                            <div class="col-md-6 ">
                                <div class="title-block ">
                                    <!-- <span>Contact</span> -->
                                    <p class="lates " style="font-family: heavy; color: #f26a50; ">
                                        let's talk</p>
                                    <p class="text-desc ">Do you have questions for us? call us, reach us on our social media platforms, or if you aren't bothered. Kindly fill out the form below:
                                    </p>
                                </div>
                                <div class="last-checkbox-container">
                                    <label class="container" for="inPersonal" style="font-size: 10px; letter-spacing: 0.10em;">
                                        <input type="radio" checked (change)="setRadio('inPersonal')" id="inPersonal"
                                            name="connect" value="inPersonal">&nbsp;
                                        <span class="checkmark"></span>InPersonal
                                    </label>&nbsp;&nbsp;
                                    <label class="container" for="videoCall" style="font-size: 10px; letter-spacing: 0.10em;">
                                        <input type="radio" (change)="setRadio('videoCall')" id="videoCall"
                                            name="connect" value="videoCall">&nbsp;
                                        <span class="checkmark"></span>Video Call
                                    </label>&nbsp;&nbsp;

                                    <label class="container" for="phoneCall" style="font-size: 10px; letter-spacing: 0.10em;">
                                        <input type="radio" (change)="setRadio('phoneCall')" id="phoneCall"
                                            name="connect" value="phoneCall">&nbsp;
                                        <span class="checkmark"></span>Phone Call
                                    </label>
                                </div>
                                <form class="form-container" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm(); ">
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Name ">
                                    </div>
                                    <div class="input-field">
                                        <input type="email" class="form-control" id="email" name="email" #email="ngModel" ngModel email required placeholder="Email">
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="companyName" name="companyName" #companyName="ngModel" ngModel companyName placeholder="Company Name (if any)">
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="websiteUrl" name="websiteUrl" #websiteUrl="ngModel" ngModel websiteUrl placeholder="Website" />
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="form-control" name="phone" id="phone" ng2TelInput #phone="ngModel" ngModel phone required placeholder="Phone Number" />
                                    </div>
                                    <div class="input-field ">
                                        <textarea class="form-control" id="message" name="message" #message="ngModel" ngModel message required placeholder="Message "></textarea>
                                    </div>
                                    <button class="btn-submit " type="submit " id="submit" value="Submit" [disabled]="agree==false">Request a quote</button>
                                    <!-- <input  type="checkbox " name="agreement " class="agreement "  [checked]="agree "
                                (change)="agree=! agree "  > I agree to these <a href="/terms " style="color: #fdca33; ">Terms and Conditions </a> -->
                                    <div class="last-checkbox-container " style="flex-direction: column; margin-top: 5rem; ">

                                        <label class="container" for="agree" style="text-align: left; letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family: light ">
                                            <input type="checkbox" checked  id="agree"
                                                name="connect" value="agree">&nbsp;
                                            <span class="checkmark"></span>By submitting this form, you authorize LOXIY to use your personal data to respond to your requests and queries.
                                        </label>
                                        <label class="container" for="sub" style="text-align: left; letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family: light ">
                                            <input type="checkbox" checked  id="sub"
                                                name="connect" value="sub">&nbsp;
                                            <span class="checkmark"></span>By ticking this box, you agree that we may contact you about LOXIY news, offers, and promotional materials. For more information visit the LOXIY privacy policy.
                                        </label>

                                    </div>
                                </form>
                            </div>
                            <div class=" col-md-6 d-flex align-items-center " style="padding-left:10% ">

                                <img *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu'" class="img-responsive" src="assets/img/team-page2.png" alt="Image">
                                <img *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' || homeId=='t1f8bVAOY9dC4Z15JM6V' " class="img-responsive" src="assets/img/team-page4.png " alt="Image ">
                                <img *ngIf="homeId=='jCStic4eEL39erKVEfmV' || homeId=='jId5W3MrWlwE1q9NtpHt' " class="img-responsive" src="assets/img/team-page6.png " alt="Image ">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class=" " style="background-color: #070623;">
        <app-footer></app-footer>


    </div>

</div>
import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
declare var $:any;
@Component({
  selector: 'single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.css']
})
export class BlogSingleComponent implements OnInit {
  blogId
  blog
  constructor(private route:ActivatedRoute,private fs:FireService) { }

  ngOnInit(): void {

    this.blogId = this.route.snapshot.params['id'];
    this.getBlogDetails();
  }
  getBlogDetails(){
    this.fs.getBlogDetail(this.blogId).subscribe(data => {
      this.blog = data
      })
}
}

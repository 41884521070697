import { Component,  OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  FireService } from '../../app/services/fire.service';

import { NgxSpinnerService } from "ngx-spinner";
import { NgImageSliderComponent } from 'ng-image-slider';

declare var $:any;
@Component({
  selector: 'app-homeProfile',
  templateUrl: './homeProfile.component.html',
  styleUrls: ['./homeProfile.component.scss']
})
export class homeProfileComponent implements OnInit {
  homeId
  home
  radio="inPersonal"
  agree=true
  carousel
  images:any=[]
  otherServices:any=[]


  allPrintingWorks:any=[]
  catalogue:any=[]
  contentStrategy:any=[]
  logoDesign:any=[]
  plasticCarton:any=[]
  socialMediaMarketing:any=[]
  socialMediaPosters:any=[]

corporationVideo:any=[]
documentaryVideo:any=[]
motionGraphic:any=[]
productsVideo:any=[]
transationsVideo:any=[]
@ViewChild('nav') slider: NgImageSliderComponent;


    constructor(private route:ActivatedRoute,private fs:FireService,private spinner: NgxSpinnerService) {
      this.homeId=this.route.snapshot.params['id'];
     }


    ngOnInit(): void {
this.getSpecificHomeSlider();
this.getCarousel();








    }


    getSpecificHomeSlider(){
      this.spinner.show();

      this.fs.getSpecificHomeSlider(this.homeId).subscribe(data=>{
        this.home=data
        this.spinner.hide();

      })

    }
    getCarousel(){
      this.spinner.show();
      console.log(this.homeId)
      this.fs.getCarousel(this.homeId).subscribe((data:any)=>{
        if(this.homeId=='t1f8bVAOY9dC4Z15JM6V' || this.homeId=='jId5W3MrWlwE1q9NtpHt' || this.homeId=='jCStic4eEL39erKVEfmV'){

          data.otherServices.forEach(element => {
            this.otherServices.push({"image":element,thumbImage: element})
          });
            data.images.forEach(element => {
              this.images.push({"image":element,thumbImage: element})
            });
        }
          if(this.homeId=='u6rgnpfjNgWfNfGcesju'){

            data.logoDesign.forEach(element => {
              this.logoDesign.push({"image":element,thumbImage: element})
            });
            data.socialMediaPosters.forEach(element => {
              this.socialMediaPosters.push({"image":element,thumbImage: element})
            });
            data.catalogue.forEach(element => {
              this.catalogue.push({"image":element,thumbImage: element})
            });
            // this.carousel=data;
          
            data.plasticCarton.forEach(element => {
              this.plasticCarton.push({"image":element,thumbImage: element})
            });
            data.allPrintingWorks.forEach(element => {
              this.allPrintingWorks.push({"image":element,thumbImage: element})
            });
            data.socialMediaMarketing.forEach(element => {
              this.socialMediaMarketing.push({"image":element,thumbImage: element})
            });
            data.contentStrategy.forEach(element => {
              this.contentStrategy.push({"image":element,thumbImage: element})
            });
            data.socialMediaPosters.forEach(element => {
              this.socialMediaPosters.push({"image":element,thumbImage: element})
            });

        }
        if(this.homeId=="BRN7B9CIjT5f8sl5HIBu"){

          data.corporationVideo.forEach(element => {
            this.corporationVideo.push({"image":element,thumbImage: element})
          });
          // this.corporationVideo=data.corporationVideo
          data.documentaryVideo.forEach(element => {
            this.documentaryVideo.push({"image":element,thumbImage: element})
          });
          // this.documentaryVideo=data.documentaryVideo
          data.motionGraphic.forEach(element => {
            this.motionGraphic.push({"image":element,thumbImage: element})
          });
          // this.motionGraphic=data.motionGraphic
          data.productsVideo.forEach(element => {
            this.productsVideo.push({"image":element,thumbImage: element})
          });
          // this.productsVideo=data.productsVideo
          data.transationsVideo.forEach(element => {
            this.transationsVideo.push({"image":element,thumbImage: element})
          });
          console.log(this.transationsVideo)


        }
       
        console.log(this.transationsVideo)
        this.spinner.hide()

      })

    }
    create(value){
      value.createdAt= new Date();

      value.contactType=this.radio
      value.agrement=this.agree
      this.fs.createContactRequests(value).then(()=>{

      });
    }
     
    setRadio(val){
      this.radio=val
    }

}

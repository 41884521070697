import { Component, HostListener, OnInit, ViewChild,} from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { Loading } from '../shared/loader/loading';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  

  homeSliders

  radio="inPersonal"
  agree=true
  constructor(private fs:FireService,private spinner: NgxSpinnerService,private router:Router) {
    // this.getAllHomeSlides();
    // window.location.reload();

   }

   
  ngOnInit() {
  
    this.spinner.show();
    
    
    setTimeout(()=>{                           //<<<---using ()=> syntax
      
      this.getAllHomeSlides();

      this.spinner.hide(); 
    }, 3000);
  }

 






   @HostListener('window:scroll', ['$event']) // for window scroll events
  onscrollTop(event) {
 
      // let  v1  = <HTMLVideoElement>document.getElementById("v1");
      // v1.play();
      // let  v2  = <HTMLVideoElement>document.getElementById("v2");
      // v2.play();
      // let  v3  = <HTMLVideoElement>document.getElementById("v3");
      // v3.play();
      // let  v4  = <HTMLVideoElement>document.getElementById("v4");
      // v4.play();
      let  v5  = <HTMLVideoElement>document.getElementById("v5");
      v5.play();
      // let  v6  = <HTMLVideoElement>document.getElementById("v6");
      // v6.play();

  }
  @HostListener('document:wheel', ['$event.target'])
public onWheel(targetElement) {
 
    // let  v1  = <HTMLVideoElement>document.getElementById("v1");
    // v1.play();
    // let  v2  = <HTMLVideoElement>document.getElementById("v2");
    // v2.play();
    // let  v3  = <HTMLVideoElement>document.getElementById("v3");
    // v3.play();
    // let  v4  = <HTMLVideoElement>document.getElementById("v4");
    // v4.play();
    let  v5  = <HTMLVideoElement>document.getElementById("v5");
    v5.play();
    // let  v6  = <HTMLVideoElement>document.getElementById("v6");
    // v6.play();



}
  getAllHomeSlides(){
   
    
    this.fs.getHomeSliders().subscribe(data => {
      this.homeSliders = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })
    });

  }
  create(value){
    value.agrement=this.agree
    value.contactType=this.radio
    value.createdAt= new Date();

    this.fs.createContactRequests(value).then(()=>{

    });

  }
  setRadio(val){
    this.radio=val
  }
 


}


// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyClqrm-20_3m6qPZdadgNq6tImQ2VFAzU4",
    authDomain: "loxiy-e4e38.firebaseapp.com",
    projectId: "loxiy-e4e38",
    storageBucket: "loxiy-e4e38.appspot.com",
    messagingSenderId: "717242205383",
    appId: "1:717242205383:web:7d8ac83c3fb16c61602ae2"
  }
}

<app-navbar></app-navbar>


<ngx-spinner bdColor="#070623" size="medium" color="#f26a50" type="ball-spin-clockwise" [fullScreen]="true">

    <p style="color: white" style="margin-top: 400px;"> LOADING </p>
</ngx-spinner>
<div class="containers">

    <!-- 1st section banner-->
    <section class="row tm-section banner-background">


        <div class="row-sm-12 row-md-12 row-lg-6 row-xl-6 ">

            <div class="pb-3 tm-bg-color-primary tm-section-min-h ml-auto mr-auto">
                <p class="banner-text-title" style="font-family: heavy;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 5rem;
                font-weight: 700;
                text-transform: uppercase;">partners</p>
            </div>

        </div>


    </section>

    <!-- section2 -->
    <section class="">
        <div class="col-sm-6 col-md-12 col-lg-12 col-xl-12 ">
            <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5">
                <div class="tm-md-flex-center">
                    <h2 class="mb-4 tm-text-color-primary partners-text-title">our wordly partners</h2>
                </div>
                <h4 class="mb-4 tm-text-color-primary partners-text" style="font-family: light;">meet our partners now</h4>
            </div>
        </div>
        <!--image card layout start-->
        <div class="card-container">
            <!--image row start-->
            <div class="row ">
                <!--image card start-->

                <!-- card1 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/talentica.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">talentica</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[0].description}}</p>
                        </div> -->
                    </div>
                </div>
                <!-- card2 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/sensetime.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">sensetime</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[1].description}}</p>
                        </div> -->
                    </div>
                </div>

                <!-- card3 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/QUYTECH.jpg" alt="">
                                <!--   <div class="underline"><h2 class="underline-text">quytech</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[2].description}}</p>
                        </div> -->
                    </div>
                </div>



                <!-- card4 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/loftmedya.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">loftmedya</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[3].description}}</p>
                        </div> -->
                    </div>
                </div>
                <!--image card end-->
            </div>
            <!-- row1 end -->


            <!-- row 2 -->
            <div class="row">

                <!-- card5 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/Dji.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">Dji</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[4].description}}</p>
                        </div> -->
                    </div>
                </div>

                <!-- card6 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img style="width: 190px;" src="assets/img/partners/BostonDynamics.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">BostonDynamics</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[5].description}}</p>
                        </div> -->
                    </div>
                </div>

                <!-- card7 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/UBTECH.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">UBTECH</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[6].description}}</p>
                        </div> -->
                    </div>
                </div>

                <!-- card8 -->
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="image">
                                <img src="assets/img/partners/Contabo.jpg" alt="">
                                <!--  <div class="underline"><h2 class="underline-text">Contabo</h2></div> -->
                            </div>
                        </div>
                        <!-- <div class="flip-card-back">
                            <p class="flip-card-text"> {{partners[7].description}}</p>
                        </div> -->
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- end -->
    <!-- section3 -->
    <section class="row tm-section tm-mb-30 background-img">
        <!-- <img class="background-img" src="assets/img/partners/background.png" alt=""> -->
        <div class=" tm-bg-color-primary tm-section-min-h ml-auto mr-auto">

            <p class="img-overlay-text" style="font-family: heavy;">join us</p>
        </div>

    </section>

    <!-- end -->


    <!-- section4 -->
    <section class="row ">

        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="line-yellow2"></div>
            <div class="tm-flex-center p-5">
                <div class="tm-md-flex-center ">

                    <h2 class=" tm-text-color-primary  pt-5" style="font-family:heavy;">contact us now </h2>

                </div>
                <p style="color: #f26a50;font-size: 20px;  padding-right: 25px; font-family: light; font-size: 1.90rem">Contact@loxiy.com</p>


            </div>
            <div class="line-yellow3"></div>
        </div>

    </section>
    <!-- section4 end -->


    <!-- contact1 -->
    <div class="section ">
        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-7 wide-col-laptop">
                    <div class="contact-section">
                        <div class="row">

                            <div class="col-md-6 ">
                                <div class="title-block ">
                                    <!-- <span>Contact</span> -->
                                    <p class="lates" style="font-family: heavy; color: white; ">let's talk</p>
                                    <p class="text-desc" style="font-family: light;">do you hove any questions for us? call us, tweet us, reach us on out social media platforms or if you aren't bothered. fill out the form below:</p>
                                </div>

                                <div class="last-checkbox-container">
                                    <label class="container" for="inPersonal" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" (change)="setRadio('inPersonal')"  id="inPersonal" name="connect"  value="inPersonal">&nbsp;
                              <span class="checkmark"></span>InPersonal
                            </label>&nbsp;&nbsp;
                                    <label class="container" for="videoCall" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" (change)="setRadio('videoCall')" id="videoCall" name="connect" value="videoCall">&nbsp;
                              <span class="checkmark"></span>Video Call
                            </label>&nbsp;&nbsp;

                                    <label class="container" for="phoneCall" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" (change)="setRadio('phoneCall')" id="phoneCall" name="connect" value="phoneCall">&nbsp;
                              <span class="checkmark"></span>Phone Call
                            </label>
                                </div>
                                <form class="form-container" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Name">
                                    </div>
                                    <div class="input-field">
                                        <input type="email" class="form-control" id="email" name="email" #email="ngModel" ngModel email required placeholder="Email">
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="form-control" id="companyName" name="companyName" #companyName="ngModel" ngModel companyName placeholder="Company Name (if any)">
                                    </div>

                                    <div class="input-field ">
                                        <input type="text" class="form-control  " id="websiteUrl" name="websiteUrl" #websiteUrl="ngModel" ngModel websiteUrl placeholder="Website Url " />
                                    </div>


                                    <div class="input-field ">

                                        <input type="text" class="form-control " name="phone" id="phone" ng2TelInput #phone="ngModel" ngModel phone required placeholder="Phone Number" />

                                    </div>






                                    <div class="input-field  ">
                                        <textarea class="form-control " id="message" name="message" #message="ngModel" ngModel message required placeholder="Message"></textarea>
                                    </div>

                                    <button class="btn-submit" type="submit" id="submit" value="Submit" [disabled]="agree==false">Request a quote</button>
                                    <!-- <input  type="checkbox" name="agreement" class="agreement"  [checked]="agree"
                                (change)="agree = !agree"  > I agree to these <a href="/terms" style="color: #fdca33;">Terms and Conditions </a> -->
                                    <div class="last-checkbox-container " style="flex-direction: column; margin-top: 5rem;">

                                        <label class="container" style="text-align: left;  letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family: light; ">
                                    <input type="checkbox" name="agreement" class="agreement"
                                    (change)="agree" >
                                    <span class="checkmark"></span>By submitting this form, you authorize LOXIY to use your personal data to respond to your requests and queries.
                                  </label>
                                        <label class="container" style="text-align: left;  letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family:light; ">
                                    <input type="checkbox" name="agreement" class="agreement"
                                    (change)="agree"  >
                                    <span class="checkmark"></span>By ticking this box, you agree that we may contact you about LOXIY news, offers, and promotional materials. For more information visit the LOXIY privacy policy.
                                  </label>

                                    </div>


                                </form>
                            </div>
                            <div class="col-12 col-md-6 d-flex align-items-center">
                                <!-- <div class="col-12 col-md-7 d-flex align-items-center"> -->
                                <!-- <div class="tm-flex-center p-5" > -->
                                <!--                                 <img *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu'" class="img-responsive" src="assets/img/team-page2.png" alt="Image" >
                                <img *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' || homeId=='t1f8bVAOY9dC4Z15JM6V'"  class="img-responsive" src="assets/img/graphic-des/Asset-41.png" alt="Image" >
                                <img *ngIf="homeId=='jCStic4eEL39erKVEfmV' || homeId=='jId5W3MrWlwE1q9NtpHt'" class="img-responsive" src="assets/img/Ai Software/3.png" alt="Image" > -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <!-- <img class="img-responsive" src="assets/img/team-page2.png" alt="Image"> -->
                                <img class="img-responsive"src="assets/img/team-page3.png"  alt="Image">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="" style="background-color: #070623">
        <app-footer></app-footer>


    </div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  FireService } from '../../app/services/fire.service';

declare var $:any;
@Component({
  selector: 'app-productProfile',
  templateUrl: './productProfile.component.html',
  styleUrls: ['./productProfile.component.css']
})
export class productProfileComponent implements OnInit {
  productId
  product
  imageGallery
  products
    constructor(private route:ActivatedRoute,private fs:FireService) {
      this.productId=this.route.snapshot.params['id'];
     }
  
    ngOnInit(): void {
      this.getPRoductProfile();
      this.getProducts();
     
    }
    getPRoductProfile(){
      this.fs.getProductPRofile(this.productId).subscribe((data:any)=>{
        this.product=data
        this.imageGallery=data.imgGallery
      })
    }
    getProducts(){
      this.fs.getProducts().subscribe(data => {
        this.products = data.docs.map(e => {
          return {
            ...e.data() as any
            ,id:e.id
          } as any;
        })
    
  
      });
    }

}

<app-navbar>
</app-navbar>

<body>
    <!-- Loader -->
    <div id="loader-wrapper">
        <div id="loader"></div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
    </div>

    <div class="container" style="margin-top: 70px;">

        <div class="section animated-row" data-section="slide11">
            <div class="section-inner">
                <div class="row justify-content-center">
                    <div class="col-md-8 wide-col-laptop">
                        <div class="title-block animate" data-animate="fadeInUp">
                            <h2 *ngIf="product">{{product.title}}</h2>
                        </div>
                        <!-- <div class="col-md-8 offset-md-2" >
                      <div class="testimonials-section"  >
                          <div class="testimonials-slider owl-carousel">
                              <div    class="item animate" data-animate="fadeInUp">
                                  <div   class="testimonial-item">
                                      <div class="testimonial-content">
                                          <img style="height: 300px;width: 300px;" src="{{item}}"  alt="profile 1">
                                      </div>   
                                      
                                                                     
                                  </div>
                              </div>
                            
                          </div>
                      </div>
                  </div> -->
                    </div>
                </div>
            </div>
        </div>


        <!-- 1st section -->
        <section class="row tm-section" *ngIf="product.description!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                <div class="p-5 tm-bg-color-primary tm-section-min-h">
                    <h1 class="tm-text-color-white tm-site-name" style="text-align: left;font-family: heavy;">Description</h1>
                    <div style="text-align: left;" [innerHtml]="product.description"></div>


                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div *ngIf="product.videos.length!=0" class="tm-flex-center p-5">
                    <video class="img-fluid" preload="none" autoplay loop [muted]="'muted'" playsInline>
          <source [src]="product.videos[0]" type="video/mp4">
        </video>

                </div>
                <div *ngIf="product.videos.length==0" class="tm-flex-center p-5">

                    <img [src]="product.profile[0]" alt="Image" class="img-fluid">

                </div>
            </div>
        </section>



        <!-- 3rd Section -->
        <section class="row tm-section tm-mb-30" *ngIf="product.design!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0 text-center">
                <img [src]="product.imgGallery[4]" alt="Image" class="img-fluid">


            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="tm-flex-center p-5">
                    <div class="tm-flex-center tm-flex-col">
                        <h2 style="text-align: left;font-family: heavy;" class="tm-align-left">Design</h2>
                        <div style="text-align: left;" [innerHtml]="product.design"></div>
                    </div>
                </div>
            </div>
        </section>
        <section class="row tm-section" *ngIf="product.battery!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left" style="text-align: left;font-family: heavy;">Battery</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.battery"></div>


                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="tm-flex-center p-5">
                    <img [src]="product.imgGallery[1]" alt="Image" class="img-fluid">

                </div>
            </div>
        </section>

        <section class="gallery">
            <div class="container">
                <div class="section animated-row">
                    <div class="section-inner">
                        <div class="row justify-content-center">
                            <div class="col-md-8 wide-col-laptop">

                                <div class="gallery-section">
                                    <div class="gallery-list owl-carousel">
                                        <div class="item animate" data-animate="fadeInUp">
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="product.imgGallery[1]" alt="Image" class="img-fluid">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item animate" data-animate="fadeInUp">
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="product.imgGallery[2]" alt="Image" class="img-fluid">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item animate" data-animate="fadeInUp">
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="product.imgGallery[3]" alt="Image" class="img-fluid">
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section animated-row">

                    <div class="section-inner">
                        <div class="row justify-content-center">
                            <div class="col-md-8 wide-col-laptop">

                                <div class="gallery-section">
                                    <div class="gallery-list owl-carousel">
                                        <div class="item animate" data-animate="fadeInUp">
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="product.imgGallery[4]" alt="Image" class="img-fluid">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item animate" data-animate="fadeInUp">
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="product.imgGallery[5]" alt="Image" class="img-fluid">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item animate" data-animate="fadeInUp">
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="product.imgGallery[7]" alt="Image" class="img-fluid">
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </section>
        <!-- / projects -->


        <section class="row tm-section tm-mb-30">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5">
                    <div class="tm-md-flex-center">
                        <h2 class="mb-4 tm-text-color-primary" style="font-family: heavy;">Let's Talk: </h2>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='6Ok1XXcAsYlWIo3QP6aI'">damas@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='CZgGfucpHIpITwPdBnsG'">lorem@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='DvwWOxIRw22A74d8fgsU'">axa@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='IzT2cjS3f5Kkszi4ZrCB'">taja@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='RHXqiCcgt4hsxoR9LSoS'">azxon@loxiy.com</p>

                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='v7kfojIMWIeGX6P0Rc3C'">ciber@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='RsvLcDAxkDLBqcemHCzY'">Kuatras@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='Yak8saRWW6wy8emXdJk1'">Otanus@loxiy.com</p>
                        <p style="color: #f26a50;font-size: 20px;" *ngIf="productId=='NI6SPmTYQNekv0nHt115'">TireX@loxiy.com</p>



                    </div>
                </div>
            </div>
        </section>





        <section class="row tm-section" *ngIf="product.advantages!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">Advantages</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.advantages"></div>


                </div>
            </div>
        </section>

        <section class="row tm-section" *ngIf="product.disAdvantages!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">disAdvantages</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.disAdvantages"></div>


                </div>
            </div>
        </section>
        <section class="row tm-section" *ngIf="product.application!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">Application</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.application"></div>


                </div>
            </div>
        </section>


        <section class="row tm-section" *ngIf="product.environment!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 *ngIf="product.environment!=undefined" class="tm-align-left">environment</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.environment"></div>


                </div>
            </div>
        </section>

        <section class="row tm-section" *ngIf="product.futureVersions!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">future Versions</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div [innerHtml]="product.futureVersions"></div>


                </div>
            </div>
        </section>

        <section class="row tm-section" *ngIf="product.generation!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2>Generation</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div [innerHtml]="product.generation"></div>


                </div>
            </div>
        </section>
        <section class="row tm-section" *ngIf="product.moreInformation!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">More Information</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.moreInformation"></div>


                </div>
            </div>
        </section>
        <section class="row tm-section" *ngIf="product.overview!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">Overview</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.overview"></div>


                </div>
            </div>
        </section>
        <section class="row tm-section" *ngIf="product.performanceAndSoftware!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">performance And Software</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.performanceAndSoftware"></div>


                </div>
            </div>
        </section>
        <section class="row tm-section" *ngIf="product.problem!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">Problem</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.problem"></div>


                </div>
            </div>
        </section>

        <section class="row tm-section" *ngIf="product.sensors!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">Sensors</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.sensors"></div>


                </div>
            </div>
        </section>

        <section class="row tm-section" *ngIf="product.solution!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 class="tm-align-left">Solution</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.solution"></div>


                </div>
            </div>
        </section>

        <section class="row tm-section" *ngIf="product.workMethod!='undefined'">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12 p-0">
                <div class="tm-flex-center p-5 tm-bg-color-primary tm-section-min-h">
                    <h2 style="text-align: left;" class="tm-align-left">Work Method</h2>
                    <!-- <p class="mb-4" *ngIf="product">{{product.description}}</p> -->
                    <div style="text-align: left;" [innerHtml]="product.workMethod"></div>


                </div>
            </div>
        </section>
        <h1>Loxiy Products <br> </h1>
        <section class="gallery">
            <div class="container">
                <div class="section animated-row">
                    <div class="section-inner">
                        <div class="row justify-content-center">
                            <div class="col-md-8 wide-col-laptop">

                                <div class="gallery-section">
                                    <div class="gallery-list owl-carousel">
                                        <div>
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="products[0].profile[0]" alt="">
                                                </div>
                                                <div class="thumb-inner animate">
                                                    <h4>{{products[0].title}}</h4>
                                                    <!-- <div  [innerHtml]="products[0].description" ></div> -->

                                                    <p><a target="_blank" [routerLink]="['../',products[0].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="products[1].profile[0]" alt="">
                                                </div>
                                                <div class="thumb-inner animate">
                                                    <h4>{{products[1].title}}</h4>
                                                    <!-- <div  [innerHtml]="products[1].description" ></div> -->
                                                    <p><a target="_blank" [routerLink]="['../',products[1].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="portfolio-item">
                                                <div class="thumb">
                                                    <img [src]="products[2].profile[0]" alt="">
                                                </div>
                                                <div class="thumb-inner animate">
                                                    <h4>{{products[2].title}}</h4>
                                                    <!-- <div  [innerHtml]="products[2].description" ></div> -->
                                                    <p><a target="_blank" [routerLink]="['../',products[2].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

        </section>
        <!-- / projects -->

    </div>

</body>
<app-footer></app-footer>
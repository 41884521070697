import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
declare var $: any;
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;

    constructor(public location: Location, private element: ElementRef) {
        this.sidebarVisible = false;
    }
    home() { window.location.href = "/home"; }
    About() { window.location.href = "/aboutus"; }
    Products() { window.location.href = "/products"; }
    Blog() { window.location.href = "/blog"; }
    Career() { window.location.href = "/career"; }
    Partners() { window.location.href = "/partners"; }
    contact(){
        window.location.href = "/contactus";
    }


    ngOnInit(): void {
        //start navbar-light when we do ascroll in the window
        $(".overlay ul li a").click(function () {


            $('#toggle').toggleClass('active');
            $('#overlay').toggleClass('open');
        });
        $('#toggle').click(function () {
            $(this).toggleClass('active');
            $('#overlay').toggleClass('open');
        });



        //end navbar-light

        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        /* navbar scrolling change color */
 $(window).scroll(function() {
        $('header').toggleClass('scrolled', $(this).scrollTop() > 100 );
   })

    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }



}

import { Component, OnInit } from '@angular/core';
import {  FireService } from '../../app/services/fire.service';
import { ActivatedRoute } from '@angular/router';
// import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $:any;
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {
  partners
   radio="inPersonal"
   agree=true
  constructor(private route:ActivatedRoute,private fs:FireService) { }
  fun(i){

    var card = $('.material-card  #mc-btn-action' + i).closest('.material-card');
    var icon = $('.material-card  #mc-btn-action' + i).children('i');
    icon.addClass('fa-spin-fast');

    if (card.hasClass('mc-active')) {
        card.removeClass('mc-active');

        window.setTimeout(function() {
            icon
                .removeClass('fa-arrow-left')
                .removeClass('fa-spin-fast')
                .addClass('fa-bars');

        }, 800);
    } else {
        card.addClass('mc-active');

        window.setTimeout(function() {
            icon
                .removeClass('fa-bars')
                .removeClass('fa-spin-fast')
                .addClass('fa-arrow-left');

        }, 800);
    }

}
  create(value){

     value.createdAt= new Date();

      value.contactType=this.radio
      value.agrement=this.agree
      this.fs.createContactRequests(value).then(()=>{

      });
    }
// Images = ['../assets/images/Carousel1.jpeg', '../assets/images/Carousel2.jpeg', '../assets/images/Carousel3.jpeg'];
// SlideOptions = { items: 3, dots: true, nav: true };
// CarouselOptions = { items: 3, dots: true, nav: true };
// customOptions: OwlOptions = {
//   loop: true,
//   mouseDrag: false,
//   touchDrag: false,
//   pullDrag: false,
//   dots: false,
//   navSpeed: 700,
//   navText: ['', ''],
//   responsive: {
//     0: {
//       items: 1
//     },
//     400: {
//       items: 2
//     },
//     740: {
//       items: 1
//     },
//     940: {
//       items: 4
//     }
//   },
//   nav: true
// }
  ngOnInit(): void {
    $(function() {
      $('.material-card  .mc-btn-action').click(function () {
          var card = $(this).parent('.material-card');
          var icon = $(this).children('i');
          icon.addClass('fa-spin-fast');

          if (card.hasClass('mc-active')) {
              card.removeClass('mc-active');

              window.setTimeout(function() {
                  icon
                      .removeClass('fa-arrow-left')
                      .removeClass('fa-spin-fast')
                      .addClass('fa-bars');

              }, 800);
          } else {
              card.addClass('mc-active');

              window.setTimeout(function() {
                  icon
                      .removeClass('fa-bars')
                      .removeClass('fa-spin-fast')
                      .addClass('fa-arrow-left');

              }, 800);
          }
      });
  });
    $("body").css({
      "overflow":"auto !important"
    });

    this.getAllPartners();
  }
  getAllPartners(){
    this.fs.getPartners().subscribe(data => {
      this.partners = data.docs.map(e => {
        return {
          ...e.data() as any
          ,id:e.id
        } as any;
      })


    });
  }
   setRadio(val){
      this.radio=val
    }
}




<div class=" image" >
    
    <a ><img class="logo1" src="assets/img/logos/logo.svg"/></a>
    <!-- <img width="170px" class="logo2" src="assets/img/loxiyassets/LOGO/Black-LOGO.png" /> -->
</div>

<div class="button_container" id="toggle">

    <span class="top"></span>
    <span class="middle"></span>
    <span class="bottom"></span>
</div>




<div class="overlay" id="overlay">
    <nav class="overlay-menu">
        <ul>
            <li>
                <a (click)="home()"> <img width="100px" src="assets/img/logos/logo.svg" /></a>
            </li>
            <li><a style="cursor:pointer" (click)="home()">Home</a></li>
            <li><a style="cursor:pointer" (click)="About()">About</a></li>
            <li><a style="cursor:pointer" (click)="Products()">Products</a></li>
            <li><a style="cursor:pointer" (click)="Blog()">Blog</a></li>
            <li><a style="cursor:pointer" (click)="Career()">Career</a></li>
            <li><a style="cursor:pointer" (click)="Partners()">Partners</a></li>
            <li><a style="cursor:pointer" (click)="contact()">Contact</a></li>



            <!-- <li ><a [routerLink]="['/home']">Home</a></li>
      <li><a [routerLink]="['/aboutus']">About</a></li>
      <li><a [routerLink]="['/products']">Products</a></li>
     <li><a [routerLink]="['/services']">Servises</a></li>
      <li><a [routerLink]="['/blog']">Blog</a></li>
      <li><a [routerLink]="['/team']">Team</a></li>
      <li><a [routerLink]="['/partners']">Partners</a></li>
      <li><a [routerLink]="['/contactus']">Contact</a></li>-->
        </ul>


        <div class="svg-group">
            <ul class="list-inline list-unstyled">
                <li class="list-inline-item"><a href="http://www.facebook.com/loxiy1/" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-facebook"></i></a></li>
                <li class="list-inline-item"><a href="https://www.twitter.com/Loxiy2" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-twitter "></i></a></li>
                <!-- <li class="list-inline-item"><a href="https://loxiyy.tumblr.com/" target="_blank"><i class="fab fa-tumblr"></i></a></li> -->
                <li class="list-inline-item"><a href="http://www.instagram.com/loxiy_1/" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-instagram "></i></a></li>
                <li class="list-inline-item"><a href="https://www.linkedin.com/company/loxiy/" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-linkedin-in "></i></a></li>
                <!--<li class="list-inline-item"><a href="https://www.tiktok.com/@loxiy_1"><i class="fab fa-tiktok"></i></a></li>-->
                <!-- <li class="list-inline-item"><a href="https://www.reddit.com/user/Loxiy" target="_blank"><i class="fab fa-reddit-alien"></i></a></li> -->
                <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCXnNCRmfMKvb2-BU7Nc_S3w" target="_blank"><i style="font-family: 'FontAwesome' " class="fab fa-youtube "></i></a></li>

          

            </ul>
        </div>



    </nav>
</div>
<app-navbar></app-navbar>

<!-- Loader -->
<!--   <div id="loader-wrapper"   >
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div> -->


<!-- 

<ngx-spinner bdColor="#070623" size="medium" color="#fde825" type="ball-spin-clockwise" [fullScreen]="true">

    <p style="color: white" style="margin-top: 400px;"> LOADING </p>
</ngx-spinner> -->

<div class="career-container">

    <!-- 1st section banner-->
    <section class="row tm-section banner-background">


        <div class="row-sm-12 row-md-12 row-lg-6 row-xl-6 ">

            <div class="pb-3 tm-bg-color-primary tm-section-min-h ml-auto mr-auto ">
                <p style="font-family: heavy;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 5rem;
                font-weight: 700;
                text-transform: uppercase;" class="banner-text-title">Career</p>
            </div>

        </div>


    </section>

    <!-- section2 -->
    <section class="" style="background-color: #070623;">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5">
                <div class="tm-md-flex-center">
                    <h2 class="mb-4 tm-text-color-primary cards-text-title" style="font-family: heavy;">career</h2>
                </div>
                <h4 class="mb-4 tm-text-color-primary cards-text" style="font-family: light;">We are inventors, creators and reventors</h4>
            </div>
            <div class="card-container">
                <div class="row">
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/4.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">graphic <br> design <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div> -->
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/2.png" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">front end <br> developer <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div> -->
                    <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/3.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">Researcher <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div>
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/1.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">graphic <br> design <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div> -->
                    <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/5.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">Machine<br>learning <br> developer <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div>
                    <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/8.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">Human <br> Manager <br> Resource <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>
                    </div>
                </div>

                <!-- <div class="row pt-5 pb-5"> -->
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/5.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">Machine<br>learning <br> developer <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div> -->
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/6.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">Sales <br> Manager <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div> -->
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/7.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">application <br> developer <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>


                    </div> -->
                    <!-- <div class="cards">
                        <div class="text-over">
                            <img src="assets/img/career/8.jpg" alt="">
                            <div class="card-text-overlay">
                                <p class="text-overlay" style="font-family: light;">Human <br> Manager <br> Resource <br> needed</p>
                            </div>
                        </div>
                        <div class="card-header">
                            <a href="career/#down" style="font-family: heavy;color: black;font-size: larger;">apply now</a>
                        </div>
                    </div> -->

                <!-- </div> -->
            </div>
        </div>
    </section>
    <!-- end -->

    <!-- section3 -->
    <section class="row tm-section tm-mb-30">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="tm-flex-center pl-5 pr-5 pt-5 pb-5">
                <div class="tm-md-flex-center">
                    <h2 class="mb-4 tm-text-color-primary cards-text-title" style="font-family: heavy;">other jobs below</h2>
                </div>
                <h4 class="mb-4 tm-text-color-primary cards-text" style="font-family: light;">join our staff now and apply to your job</h4>

            </div>
        </div>
        <img class="background-img1" src="assets/img/career/career1.jpg" alt="">

    </section>
    <!-- <section class="row tm-section tm-mb-30">

        <img class="background-img" src="assets/img/career/career.jpg" alt="">

    </section> -->

    <!-- end -->

    <div class="section " id="down">
        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-7 wide-col-laptop">
                    <div class="contact-section">
                        <div class="row">

                            <div class="col-md-6">
                                <form id="contact" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                                    <h2 style="font-family: heavy;font-weight: bold;">HIRE ME</h2>
                                    <h4 style="font-family: light;font-weight: bold;">CONTACT US TO GET YOUr opportunities</h4>
                                    <fieldset>

                                        <select (change)="onChange($event.target.value)" style="font-size:13px;font-family: light;color: gray;width: 100%;outline:0px;" required>
                                    <option value="" disabled selected hidden>Job Title (ex. Building Apps,Ai Software ...etc)</option>
                                    <option value="1">building Websites</option>
                                    <option value="2">Building Apps</option>
                                    <option value="3">Graphic design</option>
                                    <option value="4">editing videos</option>
                                    <option value="5">Social Media Marketing</option>
                                    <option value="6">Artificial intelligence</option>
                                    <option value="7">Ai Software</option>
                                    </select>
                                    </fieldset>



                                    <fieldset>

                                        <select name="jobTitle" #jobTitle="ngModel" ngModel jobTitle style="font-size:13px;font-family: light;color: gray;width: 100%;outline:0px;" required>
                                <option value="" disabled selected hidden>Job Title (ex. learning machine, ...etc)</option>
                                <option *ngFor="let val of subSelectForJo" value="{{val}}">{{val}}</option>
                            </select>
                                    </fieldset>
                                    <!-- <fieldset> -->
                                    <input id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Full Name..." type="text" tabindex="1" required autofocus style="color: gray;font-family: light;" />
                                    <!-- </fieldset> -->
                                    <fieldset>
                                        <input name="email" #email="ngModel" ngModel email required placeholder="Name" placeholder="Email..." type="email" tabindex="2" required style="font-family: light;" />
                                    </fieldset>
                                    <fieldset>
                                        <input name="Number" #Number="ngModel" ngModel Number required placeholder="Tele Number... (optional)" type="tel" tabindex="3" required style="font-family: light;" />
                                    </fieldset>
                                    <fieldset>
                                        <input name="Site" #Site="ngModel" ngModel Site required placeholder="Url..." type="url" tabindex="4" required style="font-family: light;" />
                                    </fieldset>

                                    <fieldset>
                                        <textarea name="message" #message="ngModel" ngModel message required placeholder="Add note...." tabindex="5" required style="font-family: light;;"></textarea>
                                    </fieldset>
                                    <fieldset>
                                        <button id="buttonStyle" type="button" (click)="cvUpload()" class="btn only" style="font-family: light;"><i  class="fas fa-cloud-upload" style="font-family: 'FontAwesome' " aria-hidden="true"></i>
                              Upload Cv</button>
                                        <button id="buttonStyle" type="button" (click)="letterUpload()" class="btn only" style="font-family: light;">
                                <i class="fas fa-cloud-upload" aria-hidden="true" style="font-family: 'FontAwesome' "></i> Cover Letter</button>
                                        <button class="btn only" name="submit" type="submit" style="font-family: light;font-weight: bold;" id="contact-submit" data-submit="...Sending">
                                Submit
                              </button>



                                    </fieldset>

                                    <div class="form-control">
                                        <input type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" (change)="selectCv($event)" id="cv" name="cv" hidden="hidden" #cv="ngModel" ngModel cv>
                                        <input type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" placeholder="CoverLetter" (change)="selectCoverLetter($event)" id="letter" name="letter" #letter="ngModel" ngModel letter hidden="hidden">
                                    </div>

                                </form>
                            </div>
                            <div class="col-12 col-md-6 d-flex align-items-center">
                                <!-- <div class="col-12 col-md-7 d-flex align-items-center"> -->
                                <!-- <div class="tm-flex-center p-5" > -->
                                <!--                                 <img *ngIf="homeId=='BRN7B9CIjT5f8sl5HIBu'" class="img-responsive" src="assets/img/team-page2.png" alt="Image" >
                                <img *ngIf="homeId=='u6rgnpfjNgWfNfGcesju' || homeId=='t1f8bVAOY9dC4Z15JM6V'"  class="img-responsive" src="assets/img/graphic-des/Asset-41.png" alt="Image" >
                                <img *ngIf="homeId=='jCStic4eEL39erKVEfmV' || homeId=='jId5W3MrWlwE1q9NtpHt'" class="img-responsive" src="assets/img/Ai Software/3.png" alt="Image" > -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <!-- <img class="img-responsive" src="assets/img/loxiyassets/LOXIY-HIRE-ME.png" alt="Image"> -->
                                <img class="img-responsive"src="assets/img/team-page6.png"  alt="Image">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- seciton4 -->


    <!-- <div class="container mt-md-5">

        <div class="row d-flex flex-column-reverse flex-md-row ">
            <div class="col-12 col-md-5">
                <form id="contact" #formData="ngForm" (ngSubmit)="create(formData.value); formData.resetForm();">
                    <h3 style="font-family: heavy;font-weight: bold;">HIRE ME</h3>
                    <h4 style="font-family: heavy;font-weight: bold;">CONTACT US TO GET YOUr opportunities</h4>
                    <fieldset>

                        <select (change)="onChange($event.target.value)" style="font-size:13px;font-family: light;color: gray;width: 100%;outline:0px;" required>
                      <option value="" disabled selected hidden>Job Title (ex. Building Apps,Ai Software ...etc</option>
                      <option value="1">building Websites</option>
                      <option value="2">Building Apps</option>
                      <option value="3">Graphic design</option>
                      <option value="4">editing videos</option>
                      <option value="5">Social Media Marketing</option>
                      <option value="6">Artificial intelligence</option>
                      <option value="7">Ai Software</option>
                  </select>
                    </fieldset>
                    <fieldset>

                        <select name="jobTitle" #jobTitle="ngModel" ngModel jobTitle style="font-size:13px;font-family: light;color: gray;width: 100%;outline:0px;" required>
                    <option value="" disabled selected hidden>Job Title (ex. learning machine, ...etc</option>
                    <option *ngFor="let val of subSelectForJo" value="{{val}}">{{val}}</option>
                </select>
                    </fieldset>
                    <fieldset>
                        <input id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Full Name..." type="text" tabindex="1" required autofocus style="font-family: light" />
                    </fieldset>
                    <fieldset>
                        <input name="email" #email="ngModel" ngModel email required placeholder="Name" placeholder="Email..." type="email" tabindex="2" required style="font-family: light" />
                    </fieldset>
                    <fieldset>
                        <input name="Number" #Number="ngModel" ngModel Number required placeholder="Tele Number... (optional)" type="tel" tabindex="3" required style="font-family: light" />
                    </fieldset>
                    <fieldset>
                        <input name="Site" #Site="ngModel" ngModel Site required placeholder="Url..." type="url" tabindex="4" required style="font-family: light" />
                    </fieldset>

                    <fieldset>

                        <label style="font-size: x-large;">Add note....</label>
                        <textarea name="message" #message="ngModel" ngModel message required placeholder="Add note...." tabindex="5" required style="font-family: light"></textarea>
                    </fieldset>
                    <fieldset>
                        <button id="buttonStyle" type="button" (click)="cvUpload()" class="btn only"><i class="fa  fa-cloud-upload" aria-hidden="true"></i>
                  Upload Cv</button>
                        <button id="buttonStyle" type="button" (click)="letterUpload()" class="btn only">
                    <i class="fa  fa-cloud-upload" aria-hidden="true"></i> Cover Letter</button>
                        <button class="btn only" name="submit" type="submit" id="contact-submit" data-submit="...Sending">
                    Submit
                  </button>



                    </fieldset>

                    <div class="form-control">
                        <input type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" (change)="selectCv($event)" id="cv" name="cv" hidden="hidden" #cv="ngModel" ngModel cv>
                        <input type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" placeholder="CoverLetter" (change)="selectCoverLetter($event)" id="letter" name="letter" #letter="ngModel" ngModel letter hidden="hidden">
                    </div>

                </form>
            </div>


            <div class="col-12 col-md-7 d-flex align-items-center">
                <img src="assets/img/loxiyassets/LOXIY-HIRE-ME.png">
            </div>
        </div>
    </div> -->
    <!-- seciton4 end -->

    <!-- section5 -->
    <section class="row ">

        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="line-yellow2"></div>
            <div class="tm-flex-center p-5">
                <div class="tm-md-flex-center ">

                    <h2 class=" tm-text-color-primary  pt-5" style="font-family: heavy;font-weight: bold;">hire me </h2>

                </div>
                <p style="color: #f26a50;font-size: 20px;  padding-right: 25px; font-family: light; font-size: 1.90rem">Career@loxiy.com</p>


            </div>
            <div class="line-yellow3"></div>
        </div>

    </section>
    <!-- section5 end -->

    <!-- contact1 -->
    <!-- <div class="section ">
        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-7 wide-col-laptop">
                    <div class="contact-section">
                        <div class="row">

                            <div class="col-md-6">
                                <div class="title-block">
                                    <p class="lates" style="font-family: heavy; color: #f26a50; ">let's talk</p>
                                    <p class="text-desc" style="font-family: light;">do you have any questions for us? call us, tweet us, reach us on out social media platforms or if you aren't bothered. fill out the form below:</p>
                                </div>

                                <div class="last-checkbox-container">
                                    <label style="font-family: light;" class="container" for="inPersonal" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" style="font-family: light;"  (change)="setRadio('inPersonal')"  id="inPersonal" name="connect"  value="inPersonal">&nbsp;
                              <span class="checkmark"></span>InPersonal
                            </label>&nbsp;&nbsp;
                                    <label style="font-family: light;" class="container" for="videoCall" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input type="radio" style="font-family: light;"  (change)="setRadio('videoCall')" id="videoCall" name="connect" value="videoCall">&nbsp;
                              <span class="checkmark"></span>Video Call
                            </label>&nbsp;&nbsp;

                                    <label style="font-family: light;" class="container" for="phoneCall" style="font-size: 10px; letter-spacing: 0.10em;">
                              <input  style="font-family: light;"  type="radio" (change)="setRadio('phoneCall')" id="phoneCall" name="connect" value="phoneCall">&nbsp;
                              <span class="checkmark"></span>Phone Call
                            </label>
                                </div>
                                <form class="form-container" #formData1="ngForm" (ngSubmit)="contactus(formData.value); formData1.resetForm();">
                                    <div class="input-field">
                                        <input style="font-family: light;" type="text" class="form-control contactform" id="userName" name="userName" #userName="ngModel" ngModel userName required placeholder="Name">
                                    </div>
                                    <div class="input-field">
                                        <input style="font-family: light;" type="email" class="form-control contactform" id="email" name="email" #email="ngModel" ngModel email required placeholder="Email">
                                    </div>
                                    <div class="input-field">
                                        <input style="font-family: light;" type="text" class="form-control contactform" id="companyName" name="companyName" #companyName="ngModel" ngModel companyName placeholder="Company Name (if any)">
                                    </div>

                                    <div class="input-field ">
                                        <input style="font-family: light;" type="text" class="form-control  contactform" id="websiteUrl" name="websiteUrl" #websiteUrl="ngModel" ngModel websiteUrl placeholder="Website " />
                                    </div>


                                    <div class="input-field ">

                                        <input style="font-family: light;" type="text" class="form-control contactform" name="phone" id="phone" ng2TelInput #phone="ngModel" ngModel phone required placeholder="Phone Number" />

                                    </div>






                                    <div class="input-field  ">
                                        <textarea style="font-family: light;" class="hire" class="form-control contactform " id="message" name="message" #message="ngModel" ngModel message required placeholder="Message"></textarea>
                                    </div>

                                    <button style="font-family: light;" class="btn-submit" type="submit" id="submit" value="Submit" [disabled]="agree==false">Request a quote</button>
                               
                                    <div class="last-checkbox-container " style="flex-direction: column; margin-top: 5rem;">

                                        <label class="container" style="text-align: left;  letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family: light; ">
                                    <input type="checkbox" name="agreement" class="agreement"
                                    (change)="agree" >
                                    <span class="checkmark"></span>by submitting form. you outhorize us to use your personal data to respond to your requests andlor queries
                                  </label>
                                        <label class="container" style="text-align: left;  letter-spacing: 0.15em; text-transform: capitalize; font-size: 12px; font-family:light; ">
                                    <input type="checkbox" name="agreement" class="agreement"
                                    (change)="agree"  >
                                    <span class="checkmark"></span>by ticking this box you agree that we my contact you about LOXIY's news, offers and promotional materila. For further information visit out Privacy Policy
                                  </label>

                                    </div>


                                </form>
                            </div>
                            <div class="col-12 col-md-6 d-flex align-items-center">
                                
                                <img class="img-responsive" src="assets/img/team-page2.png" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div> -->
    <div class="" style="background-color: #070623;">
        <app-footer></app-footer>


    </div>
    <!-- <div class="container mt-md-5"> -->


    <!-- </div> -->
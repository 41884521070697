<app-navbar>

</app-navbar>

<ngx-spinner bdColor="#070623" size="medium" color="#f26a50" type="ball-spin-clockwise" [fullScreen]="true">

    <p style="color: white" style="margin-top: 400px;"> LOADING </p>
</ngx-spinner>
<header>


</header>
<video style="height: 400px;" class="img-fluid" autoplay loop [muted]="'muted'" playsInline>
	<source src="../../assets/img/loxiyassets/aiproduct.mp4" type="video/mp4">
  </video>

<!-- <div *ngIf="homeSliders" > -->
<!-- <video  style="height: 500px;width: 1000px;"   autoplay loop [muted]="'muted'" >
		<source [src]="homeSliders[4].img" type="video/mp4">
	  </video> -->

<!-- </div> -->

<div id="top"  style="background-color:#070623 ;"></div>
<!-- <section class="gallery" > -->
<div class="container" style="background-color:#070623 ;">
    <h1 style="margin-top: 30px;font-family:heavy;">Loxiy Products <br> </h1>

    <div class="section animated-row">
        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-8 wide-col-laptop">

                    <div class="gallery-section">
                        <div class="gallery-list owl-carousel">
                            <!-- <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[0].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[0].title}}</h4>

                                        <p><a target="_blank" [routerLink]="['../productProfile',products[0].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div> -->
                            <div d>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[1].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[1].title}}</h4>
                                        <!-- <div  [innerHtml]="products[1].description" ></div> -->
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[1].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[2].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[2].title}}</h4>
                                        <!-- <div  [innerHtml]="products[2].description" ></div> -->
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[2].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[5].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[5].title}}</h4>
                                        <!-- <div  [innerHtml]="products[5].description" ></div> -->
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[5].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="section animated-row">

        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-8 wide-col-laptop">

                    <div class="gallery-section">
                        <div class="gallery-list owl-carousel">
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[3].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[3].title}}</h4>
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[3].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[4].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[4].title}}</h4>
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[4].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[5].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[5].title}}</h4>
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[5].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section animated-row">

        <div class="section-inner">
            <div class="row justify-content-center">
                <div class="col-md-8 wide-col-laptop">

                    <div class="gallery-section">
                        <div class="gallery-list owl-carousel">
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[6].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[6].title}}</h4>
                                        <!-- <div  [innerHtml]="products[3].description" ></div> -->
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[6].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>
                           <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[7].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[7].title}}</h4>
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[7].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="portfolio-item">
                                    <div class="thumb">
                                        <img [src]="products[8].profile[0]" alt="">
                                    </div>
                                    <div class="thumb-inner animate">
                                        <h4>{{products[8].title}}</h4>
                                        <p><a target="_blank" [routerLink]="['../productProfile',products[8].id]" style="color: white;background-color: gray;" class="btn" type="submit">view</a></p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<!-- / projects -->
<app-footer ></app-footer>